.side-navigation {
  display: flex;
  flex-direction: column;
  width: 62px;
  visibility: visible;
  background: var(--menu-background-color);
  color: var(--menu-color);
  position: fixed;
  overflow: hidden;
  z-index: 30;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding-top: 2rem;
  overflow: hidden;
  transition: width 0.3s cubic-bezier(0.25, 0.1, 0.24, 1);
  .items {
    margin-top: 20px;
  }
  .logo-small {
    margin-left: 19px;
    margin-top: 4px;
    display: block;
    margin-bottom: 4px;
  }
  .logo-large {
    display: none;
    margin-left: 19px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  :global(.menu-text) {
    vertical-align: middle;
    padding-left: 0.8rem;
  }

  @media (--mobile-only) {
    display: none;
  }
  li a {
    color: var(--menu-color);
    overflow: hidden;
  }
  li a:global(.active) {
    background-color: var(--menu-selected-background);
  }
  svg path {
    fill: var(--menu-color);
  }
}

.side-navigation:hover {
  width: 250px;
  transition: width 0.3s cubic-bezier(0.25, 0.1, 0.24, 1);
  .logo-small {
    display: none;
  }
  .logo-large {
    display: block;
  }
}

:global(.impersonating) .side-navigation {
  top: 40px;
}


