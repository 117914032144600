:global {
  .site-footer {
    
    text-align: center;
    padding: 1rem 3rem;
    p {
      margin-top: .5rem;
      font-size: 1.4rem;
      a {
        margin-left: .5rem;
      }
    }
    
  }
}
