.discography-switcher {
  margin-bottom: 2rem;
  .prefix {
    font-family: var(--base-font-family);
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.3rem;
    display: block;
    width: 100%;
    @media (--tablet) {
      width: auto;
      display: inline-block;
    }
  }
  .switcher-link {
    background-color: #dcdcdd;
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
    color: #4a4a49;
    border: none;
    transition: all 0.5s ease;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    text-transform: none;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 3rem;
    .icon {
      position: relative;
      top: 2px;
      margin-right: 0.3rem;
      /* Fixed width and inline-block prevents flickering when switching between states */
      width: 16px;
      display: inline-block;
    }
    &.my-discography:hover,
    &.my-discography:global(.active) {
      background-color: #edd0e6;
      color: var(--magenta-dark);
    }

    &.gramo-discography:hover,
    &.gramo-discography:global(.active) {
      background-color: #cfe9ef;
      color: #44646c;
    }
    @media (--tablet) {
      margin: 0 1rem;
    }
  }
}
.tabs-navigation {
  .tabs-navigation-bar {
    display: flex;
    justify-content: space-between;
  }
  @media (--mobile-only) {
    .tabs-navigation-text {
      display: none;
    }
  }
}
