.register {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  color: var(--dialog-color);
  h1,
  h2,
  h3,
  h4,
  p {
    text-align: center !important;
  }
  display: flex;

  p {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  a {
    text-decoration: underline !important;
  }
  @media (--mobile-only) {
    justify-content: center;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--disabled-grey);
    margin: 30px 0;
    padding: 0;
  }
  h3 {
    margin: 0 !important;
    padding: 0 !important;
    font-size: var(--base-font-size) !important;
  }
}

.register-form {
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-basis: 40%;
  @media (--mobile-only) {
    flex-basis: 90%;
  }
  @media (--tablet) {
    padding-left: 60px;
    padding-right: 60px;
  }
  form p {
    text-align: left !important;
  }

}

.gender {
  padding-top: 0.8rem;
  display: flex!important;
  align-items: center;
  gap: 0.5rem;
}

.submitted path {
  fill: white!important;
}

.submitted :global(.submitted-circle) {
  fill: var(--primary)!important;
}


.captcha {
  display: flex;
  width: 100%;
  justify-content: center;
}
