.empty {
  display: flex;
  @media (--mobile-only) {
    flex-wrap: wrap;
    justify-content: center;
  }

  .helper-text {
    opacity: 0.5;
    font-size: var(--small-font-size);
  }
}
