.document-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 3rem;
  padding-bottom: 0.5rem;
  li {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 1fr 0.1fr;
    gap: 1rem;
  }
}

.description {
  color: darkgray;
}

.document {
  display: flex;
  flex-direction: column;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
