/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-Light.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-Light.woff") format("woff");
    font-weight: 300;
  }
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-ThinItalic.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-ThinItalic.woff") format("woff");
    font-style: italic;
    font-weight: 300;
  }

  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-Book.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-Book.woff") format("woff");
  }
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-BookItalic.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-BookItalic.woff") format("woff");
    font-style: italic;
  }

  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-Medium.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-Medium.woff") format("woff");
    font-weight: 500;
  }
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-MediumItalic.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-MediumItalic.woff") format("woff");
    font-style: italic;
    font-weight: 500;
  }
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-Bold.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-Bold.woff") format("woff");
    font-weight: bold;
  }
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-BoldItalic.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-BoldItalic.woff") format("woff");
    font-style: italic;
    font-weight: bold;
  }
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-Black.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-Black.woff") format("woff");
    font-weight: 800;
  }
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-BlackItalic.woff2") format("woff2"),
      url("/assets/fonts/CircularXXWeb-BlackItalic.woff") format("woff");
      font-style: italic;
      font-weight: 800;
  }
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-ExtraBlack.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-ExtraBlack.woff") format("woff");
    font-weight: 900;
  }
  
  @font-face {
    font-family: "CircularXX";
    src: url("/assets/fonts/CircularXXWeb-ExtraBlackItalic.woff2") format("woff2"),
    url("/assets/fonts/CircularXXWeb-ExtraBlackItalic.woff") format("woff");
    font-style: italic;
    font-weight: 900;
  }
  
  
  