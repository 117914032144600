.side-form-page {
  display: flex;
  section {
    flex: 1;
  }
  :global(.container) > div {
    max-width: 800px;
  }
}

.icon {
  svg {
    fill: white;
  }
}

:global(.wizard-header) {
  margin-bottom: 25px;
  ol {
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    margin: 0;
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    @media (--mobile-only) {
      font-size: 14px;
    }
  }
  ol:before {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    background-color: var(--ocean-blue);
    top: 14px;
    left: 0%;
    right: 0%;
    border-radius: var(--input-border-radius);
    margin: auto;
    z-index: 3;
  }
  ol:after {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    background-color: var(--disabled-grey);
    left: 0%;
    right: 0%;
    top: 14px;
    border-radius: var(--input-border-radius);
    margin: auto;
    z-index: 2;
  }
  ol:global(.complete1):before {
    right: 90%;
  }
  ol:global(.complete2):before {
    right: 70%;
  }
  ol:global(.complete3):before {
    right: 50%;
  }
  ol:global(.complete4):before {
    right: 30%;
  }
  ol:global(.complete5):before {
    right: 10%;
  }
  ol:global(.complete6):before {
    right: 0%;
  }
  li {
    width: 20%;
    text-align: center;
  }
  li:before {
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    margin: auto;
    text-align: center;
    color: var(--disabled-grey);
    z-index: 20;
    border-radius: var(--input-border-radius);
    background-color: var(--disabled-grey);
    position: relative;
  }
  li:global(.active) {
    color: var(--ocean-blue);
    text-decoration: underline;
    cursor: pointer;
    &:before {
      background-color: var(--ocean-blue);
    }
    &:hover span {
      opacity: 0.7;
    }
  }
}

.hidden {
  display: none;
}
