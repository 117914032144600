.search-menu {
  display: flex;
  flex-direction: column;

  transform: translateX(9000px);

  position: fixed;
  overflow: auto;
  z-index: 9000;

  opacity: 0;
  transition: opacity 0.1s ease-in, transform 0s 0.1s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &.visible {
    visibility: visible;
    transform: translateX(0);
    box-shadow: -3px 3px 5px 0px color(var(--off-white) a(50%));
    transition: opacity 0.1s ease-in;
    opacity: 1;
  }

  top: 64px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  background: linear-gradient(208.22deg, #a41681 5.17%, #1d1d1d 98.21%);
  font-weight: normal;
  padding-top: 10px;
  @media (--tablet) {
    top: 0px;
    background: none;
    background-color: rgb(0, 0, 0, 0.7);
    padding: 0;
    .search-menu-content {
      top: 0px;
      bottom: auto;
      background: none;
      background-color: var(--dialog-background-color);
      :global(.search) {
        z-index: 9000;
        background-color: var(--dialog-background-color);
      }
    }
  }
}

:global(.impersonating) .search-menu {
  top: 104px;
  @media (--tablet) {
    top: 40px;
  }
}
