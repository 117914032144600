/* prettier-ignore */
:root {
  /* Global Colors */

  --ocean-blue:                                    #003dff;
  --ocean-light-blue:                              #00abff;
  --lavender:                                      #e4ebff;
  --magenta-dark:                                  #a41681;
  --magenta-light:                                 #de1cae;
  --sunrise-peach:                                 #F6AD40;
  --sunrise-warm:                                  #e8d644;
  --forest-green:                                  #8ddea0;
  --lime-green:                                    #60f06f;
  --pale-red:                                      #FF7272;
  --black:                                         #000000;
  --text-black:                                    #121212;
  --background-black:                              #2b2b2b;
  --very-dark-grey:                                #3f3f3f;
  --container-dark-grey:                           #484848;
  --subtle-grey:                                   #797979;
  --almost-grey:                                   #797979;
  --selection-grey:                                #e7e7e7;
  --disabled-grey:                                 #dedede;
  --off-grey:                                      #afafaf;
  --off-white:                                     #fafafa;
  --white:                                         #ffffff;

  --text-color:                                    var(--white);

  /* Global Typography */
  --base-font-family:                              'CircularXX', sans-serif;
  --base-font-size:                                16px;
  --base-line-height:                              20px;
  --base-font-weight:                              400;
  --base-font-color:                               var(--text-color);
  --base-link-color:                               var(--text-color);
  --heading-color:                                 var(--text-color);

  --small-font-size:                               13px;

  /* Font Weights */
  --thin:                                          300;
  --normal:                                        400;
  --medium:                                        500;
  --bold:                                          600;

  /* Headings */
  --heading-font-family:                           var(--base-font-family);
  --h1-font-size:                                  40px;
  --h2-font-size:                                  32px;
  --h3-font-size:                                  24px;
  --h4-font-size:                                  var(--base-font-size);

  /* Forms */
  --base-placeholder-color:                        var(--text-color);
  --form-elements-height:                          44px;
  --highlight-color:                               var(--ocean-blue);

  /*Tables */
  --table-border-color:                            var(--container-dark-grey);
  --table-head-background:                         var(--container-dark-grey);
  --table-row-height:                              4.8rem;

  /* Grid Containers */
  --container-mobile:                              100%;
  --container-tablet:                              768px;
  --container-desktop:                             960px;
  --container-hd:                                  1400px;

  /*Context specific variables */
  --base-background-color:                         var(--background-black);
  --container-background-color:                    var(--container-dark-grey);
  --container-border-radius:                       10px;

  --header-background-color:                       var(--black);
  --header-color:                                  var(--white);
  --menu-background-color:                         var(--black);
  --menu-color:                                    var(--white);

  --dialog-background-color:                       var(--white);
  --dialog-color:                                  var(--black);

  --simple-background-color:                       var(--white);
  --simple-color:                                  var(--black);

  --input-border-radius:                           45px;
  --compact-border-radius:                         5px;

  --default-background-gradient:                   linear-gradient(180deg, var(--ocean-blue) 0%, rgba(0, 0, 0, 0) 40%);
  --release-background-gradient:                   var(--default-background-gradient);
  --recording-background-gradient:                 linear-gradient(180deg, var(--magenta-dark) 0%, rgba(0, 0, 0, 0) 40%);
  --group-background-gradient:                     linear-gradient(180deg, var(--sunrise-peach) 0%, rgba(0, 0, 0, 0) 40%);

  --gradient-background-size:                      100%;

  --menu-border:                                   solid 1px var(--selection-grey);
  --menu-box-shadow:                               0px 0px 12px -5px var(--container-dark-grey);
  --menu-selected-background:                      rgba(222, 222, 222, 0.3);

  /* States */
  --primary:                                       var(--ocean-blue);
  --secondary:                                     var(--white);

  --success:                                       #1dcf4a;
  --error:                                         #ff0000;
  --info:                                          #f6ad40;

  --link-color:                                    var(--font-color);
  --inline-link-color:                             var(--ocean-blue);
}

@custom-media --mobile-only (max-width: 768px); /* Makes the rule(s) applicable on mobile only */
@custom-media --mobile-tablet-only (max-width: 960px); /* Makes the rule(s) applicable on mobile and tablet only */
@custom-media --tablet (min-width: 768px);
@custom-media --desktop (min-width: 960px);
@custom-media --hd (min-width: 1200px);
@custom-media --hd-plus (min-width: 1400px);
