.heading {
  text-align: center;
  padding: 2rem 0;
  margin: 0;

  & + div > section {
    padding-top: 0;
  }
}

.notifications {
  max-width: 1600px;
}
