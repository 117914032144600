:global {
  *, *:before, *:after {
    box-sizing: border-box;
  }

  * {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }

  a {
    color: var(--link-color);
    cursor: pointer;

    &:active,
    &:hover {
      outline: 0;
      text-decoration: none;
    }
    &.inline-link {
      color: var(--ocean-blue);
    }
    &.link--add {
      text-decoration: underline;
      display: inline-block;
      margin-bottom: 11px;
      margin-top: 11px;
      &::before {
        content: "+";
      }
    }
    &.link--trash {
      opacity: 0.3;
    }
    &.link--tracks, &.link--inline {
      text-decoration: underline;
    }
  }

  /* Type and fonts */
  address {
    font-style: normal;
  }

  .heading--sub {
    margin-top: 1.2rem;
    margin-bottom: 2.6rem;
  }

  p {
    margin: 0;
    padding: 1px;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }

  p.hint {
    color: var(--subtle-grey);
  }

  .search p {
    padding: 10px;
  }

  [data-type]::before {
    color: inherit;
    margin-right: 1rem;
    vertical-align: middle;
  }

  section {

  }

  main > section > header, main > div > section > header {
    margin-bottom: 30px;
  }
  section.container {
    background-color: var(--container-background-color);
    border-radius: var(--container-border-radius);
    padding: 15px;
    max-width: 1200px;
  }
  section.container--with-tabs, section.container--for-tables {
    max-width: none;
  }
  section.container--with-tabs {
    border-radius: 0 var(--container-border-radius) var(--container-border-radius) var(--container-border-radius);
    @media (--mobile-only) {
      border-radius: 0 0 var(--container-border-radius) var(--container-border-radius);
    }
  }
  section.container--light {
    background-color: var(--white);
    color: var(--black);
  }

  .positive {
    color: var(--lime-green);
  }
  .negative {
    color: var(--pale-red);
  }

  table {
    td {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
}
