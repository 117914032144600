:app-root {
  main:before {
    background-image: var(--default-background-gradient);
    background-size: var(--gradient-background-size);
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.claims-list-link {
  display: flex;
  justify-content: left;
}

.recordings-list {
  tr {
    cursor: pointer;
  }
  tr:hover {
    box-shadow: 0px 1px 1px var(--subtle-grey);
  }

  .ignored {
    color: var(--off-grey);
  }
}

.file-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  padding-left: 0.5rem;
  li {
    padding: 0.1rem;
  }
}

.file-list--row {
  max-width: 1000px;
  h4 {
    display: flex;
    gap: 1rem;
  }
  p {
    color: var(--subtle-grey);
    padding: 0;
    margin: 0;
    margin-left: 1.5rem;
  }
}

.remove-file {
  padding-left: 1.5rem;
}

.delete-file {
  margin-left: 1.5rem;
}

.file-description {
  font-style: italic;
}
