:global {
  .time-input {
    .form__label {
      display: inline-block;
    }
    .form__input {
      max-width: 80px;
    }
  }
}
