.dashlet {
  @media (--mobile-only) {
    height: auto;
  }

  margin: 13px;
  border: none;
  border-radius: var(--container-border-radius);
  background-color: var(--container-background-color);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;

  h4 {
    padding: 1.3rem;
    margin: 0;

    font-weight: 800;
    text-transform: none;
    font-family: var(--base-font-family);

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    span.icon {
      font-size: 2.6rem;
      margin-top: -0.2rem;
      vertical-align: top;
      color: #b0b8bc;
    }
    svg {
      vertical-align: top;
      margin-right: 0.3rem;
      path {
        fill: var(--base-font-color);
      }
    }
    a {
      text-decoration: none;
      svg {
        height: 14px;
        margin-top: -2px;
        vertical-align: middle;
      }
    }
  }

  .dashlet-content {
    flex: 1;
    width: 100%;
    overflow: hidden;
  }

  .no-link {
    color: var(--heading-color);
  }
}

.clickable {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  @media (--tablet) {
    &:hover {
      transform: scale(1.02);
    }
  }
}

.release {
  background: url('/assets/icons/icon-album-transparent.svg') no-repeat,
    linear-gradient(180deg, var(--ocean-blue) 0%, var(--ocean-light-blue) 100%);
  background-position: bottom right;
  background-size: contain;
  background-origin: content-box, padding-box;
}
.recording {
  background: url('/assets/icons/icon-performer-transparent.svg') no-repeat,
    linear-gradient(180deg, var(--magenta-dark) 0%, var(--magenta-light) 100%);
  background-position: bottom right;
  background-size: contain;
  background-origin: content-box, padding-box;
}
.playtime {
  background: url('/assets/icons/icon-performer-transparent.svg') no-repeat,
    linear-gradient(180deg, var(--magenta-dark) 0%, var(--magenta-light) 100%);
  background-position: bottom right;
  background-size: contain;
  background-origin: content-box, padding-box;
}
.group {
  background: url('/assets/icons/icon-groups-transparent.svg') no-repeat,
    linear-gradient(179deg, var(--sunrise-peach) 0.23%, var(--sunrise-warm) 253.13%);
  background-position: bottom right;
  background-size: contain;
  background-origin: content-box, padding-box;
}
.performer {
  background: url('/assets/icons/icon-clients-transparent.svg') no-repeat,
    linear-gradient(180deg, var(--ocean-blue) 0%, var(--ocean-light-blue) 100%);
  background-position: bottom right;
  background-size: contain;
  background-origin: content-box, padding-box;
}
.producer {
  background: url('/assets/icons/icon-clients-transparent.svg') no-repeat,
    linear-gradient(180deg, var(--magenta-dark) 0%, var(--magenta-light) 100%);
  background-position: bottom right;
  background-size: contain;
  background-origin: content-box, padding-box;
}
.payments {
  background: url('/assets/icons/icon-bank-transparent.svg') no-repeat,
    linear-gradient(180deg, var(--ocean-blue) 0%, var(--ocean-light-blue) 100%);
  background-position: bottom right;
  background-size: contain;
  background-origin: content-box, padding-box;
}
.isrc {
  background: no-repeat, linear-gradient(180deg, var(--container-dark-grey) 0%, var(--very-dark-grey) 100%);
  background-position: bottom right;
  background-size: contain;
  background-origin: content-box, padding-box;
}
