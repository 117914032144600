:global {
  .table--list {
    min-width: 100%;
    width: auto;
    margin: 0 0 1.2rem 0;

    thead {
      @media (--mobile-tablet-only) {
        display: none;
      }
    }

    tr {
      /* make <tr> a flex container on mobile */
      @media (--mobile-tablet-only) {
        flex-wrap: wrap;
        padding: 1rem;
        width: calc(100% + 2rem);
        margin: 0 -1rem;
      }
    }

    tr:not([hidden]):not(.table__row--new) {
      @media (--mobile-tablet-only) {
        display: flex;
      }
    }


    tr {
      /* collapse top and bottom borders into each other*/
      &:not(:first-child) {
        margin-top: -1px;
      }
    }

    tr td {
      flex: 0 0 auto;
      padding: 0;
      padding-top: 16px;
      padding-bottom: 16px;
      margin: 0;
      padding-left: 5px;
      padding-right: 5px;

      &:first-child {
        font-size: 2rem;
        flex: 1 0 100%;

        @media (--desktop) {
          font-size: inherit;
        }
      }

      &.table__cell--left {
        flex: 1 0 50%;
        @media (--mobile-tablet-only) {
          text-align: left!important;
        }
      }
      &.table__cell--link {
        text-decoration: underline;
      }
      &.table__cell--title {
        font-weight: var(--medium);
      }
    }

    th.table__cell--title {
      font-weight: var(--bold);
    }

    td:first-child::before {
      content: none;
    }

    @media(--mobile-only) {
      tr {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }
      td {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        border: none;
      }
    }
    td:not(:first-child):not(.table__cell--title):not(.table__cell--action):not(.table__cell--trash):not(.table__cell--delete):not(.table-row-toggle)::before {
      content: attr(data-label)":";
      display: inline-block;
      margin-right: .5rem;
      @media (--desktop) { content: none; }
    }
    td.table__cell--action::before {
      content: '' ! important;
    }

    .table__row--with-arrow {
      position: relative;
      padding-right: 2rem;
      @media (--desktop) { position: static; }

      &:after {

        color: var(--off-grey);
        top: 50%;
        right: 1rem;
        margin-top: -1rem;
        transform: rotate(-90deg);
        position: absolute;
        font-family: 'minside-icons';
        @media (--desktop) { content: none; }
      }
    }
  }

  [data-label="Beløp"], [data-label="Endring"] {
    font-weight: var(--semibold);

    &::before {
      display: none!important;
    }
  }

  .table__cell--empty::before {
    content: none!important;
  }


  .table--three {

    td:nth-child(2) {
      order: 2;
    }

    td:nth-child(3) {
      order: 3;
    }
  }

  .table--four {

    td:nth-child(2) {
      order: 2;
    }

    td:nth-child(3) {
      order: 4;
    }

    td:nth-child(4) {
      order: 3;
    }
  }

  .table--five {

    td:nth-child(2) {
      order: 2;
    }

    td:nth-child(3) {
      order: 4;
    }

    td:nth-child(4) {
      order: 3;
    }

    td:nth-child(5) {
      order: 5;
    }
  }

  .table--six {

    td:nth-child(1) {
      order: 1;
    }

    td:nth-child(2) {
      order: 2;
    }

    td:nth-child(3) {
      order: 4;
    }

    td:nth-child(4) {
      order: 6;
    }

    td:nth-child(5) {
      order: 5;
    }

    td:nth-child(6) {
      order: 3;
    }
  }

  .table--seven {

    td:nth-child(1) {
      order: 1;
    }

    td:nth-child(2) {
      order: 2;
    }

    td:nth-child(3) {
      order: 4;
    }

    td:nth-child(4) {
      order: 6;
    }

    td:nth-child(5) {
      order: 3;
    }

    td:nth-child(6) {
      order: 5;
    }

    td:nth-child(7) {
      order: 7;
    }
  }

  /* Styles for diff tables */

  .table--diff--six {

    tr td:first-child {
      font-size: inherit;
    }

    td:nth-child(1) {
      order: 1;
    }

    td:nth-child(2) {
      order: 3;
    }

    td:nth-child(3) {
      order: 5;
    }

    td:nth-child(4) {
      order: 2;
    }

    td:nth-child(5) {
      order: 4;
    }

    td:nth-child(6) {
      order: 6;
    }

    tr[data-level="2"] {

      td:nth-child(2) {
        order: 1;
      }

      td:nth-child(3) {
        order: 3;
      }

      td:nth-child(5) {
        order: 2;
      }
    }
  }

  .table--diff--nine {

    td:nth-child(1) {
      order: 1;
    }

    td:nth-child(2) {
      order: 2;
    }

    td:nth-child(3) {
      order: 4;
    }

    td:nth-child(4) {
      order: 6;
    }

    td:nth-child(5) {
      order: 8;
    }

    td:nth-child(6) {
      order: 7;
    }

    td:nth-child(7) {
      order: 3;
    }

    td:nth-child(8) {
      order: 5;
    }

    td:nth-child(9) {
      order: 9;
    }

    tr[data-level="2"] {

      td:nth-child(3) {
        order: 1;
      }

      td:nth-child(4) {
        order: 3;
      }

      td:nth-child(5) {
        order: 5;
      }

      td:nth-child(6) {
        order: 4;
      }

      td:nth-child(8) {
        order: 2;
      }

      td:nth-child(9) {
        order: 9;
      }
    }
  }

  .no-pull tr {
    width: auto;
    margin: 0;
  }
}
