:global {
  /* Min Side */
  .site-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
  }

  main:before {
    content: '';
    position: fixed;
    top: -5%;
    left: 0;
    right: 0;
    z-index: -1;

    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 110%;
    height: 110%;

    filter: blur(10px);
  }

  main {
    flex: 1 1 auto;
    position: relative; /* need this to position inner content */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 15px;
    @media (--mobile-only) {
      padding: 8px;
      padding-bottom: 8rem;
    }
    padding-bottom: 8rem;
    margin-top: 0px;
    @media (--tablet) {
      margin-left: 62px;
    }
    @media (--mobile-only) {
      margin-top: 64px;
    }
  }

  :global(.simple) main {
    margin: 0;
    padding: 0;
    background-color: var(--dialog-background-color);
    color: var(--dialog-color);
  }
  .container {
    padding: 0 1rem;

    &--padded {
      padding: 2.5rem 1rem 0 1rem;
      margin-bottom: 1rem;
    }

    &--branded {
      background-image: none;

      @media (--tablet) {
        padding-top: 5rem;
        background: none;
      }

      &__heading {
        font-size: 2.4rem;
        text-transform: none;
        margin: 3rem auto 2rem auto;
        max-width: 400px;
        text-align: center;
        font-weight: var(--thin);

        @media (--tablet) {
          margin: 2rem auto;
        }
      }
    }

    &--col-1 {
      max-width: 840px;
      margin: auto;

      &-min {
        @media (--tablet) {
          min-width: 800px;
        }
      }
    }

    &--for-tables {
      width: 100%;
      padding: 15px;
    }

    &--for-table-controls {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 1rem;
      position: relative;
      @media (--mobile-only) {
        flex-wrap: wrap;
      }
    }

    &--for-table-date-control {
      flex: 1;
      display: flex;
      @media (--mobile-only) {
        flex-wrap: wrap;
      }
      .custom-date-form {
        display: flex;
        padding-left: 5px;
        align-items: flex-end;
      }
    }

    &--for-table-side-controls {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1.2rem;
    }

    &--col-3 {
      max-width: 1100px;
    }
  }

  /* Overlay */
  .overlay {
    width: 100%;
    height: 100%;
    background: color(var(--black) a(80%));
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 8000;
    padding: 0;
    overflow-y: auto;

    @media (--tablet) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .nav--primary.nav-visible ~ main::after {
    content: '';
    animation: fadeIn ease 1s;
  }

  .nav--secondary {
    min-width: 180px;
  }

  .side-form-page {
    display: flex;
    section {
      flex: 1;
    }
    @media (--mobile-only) {
      flex-direction: column;
    }
  }

  /* Adjust container to left to avoid scrollbar caused by .row__delete */
  .adjust--to-left {
    position: static;

    @media (--tablet) {
      position: relative;
      left: -2rem;
    }

    @media (--hd) {
      position: static;
    }
  }

  .login-page__wrapper {
    margin-top: 0;
    margin-bottom: 0;

    @media (--tablet) {
      margin-top: -5rem;
      margin-bottom: -5rem;
    }
  }

  .ember-basic-dropdown-content {
    z-index: 10000;
  }
}
