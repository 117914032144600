:global {
  .select {
    padding: 1.2rem 0.75rem;
    min-width: 0;
    border: 1px solid var(--disabled-grey);
    background-color: var(--white);
    @media (--tablet) { width: 280px; }
    
    &--fluid {
      width: 100%;
    }

    &--block {
      display: block;
    }
    
    &[disabled] {
      border-color: var(--disabled-grey);
      background: var(--disabled-grey);
    }

    appearance: none;
    background: url('/assets/icons/icon-caret-down-dark.svg') no-repeat right 12px center, var(--white);
  }
  .select--control {
    border-radius: var(--input-border-radius);
    font-weight: var(--medium);
    padding: 8px;
    padding-right: 32px;
    padding-left: 12px;
    margin-top: 4px;
    @media(--mobile-only) {
      margin-bottom: 10px;
      width: 100%;
    }
    appearance: none;
    background: url('/assets/icons/icon-caret-down-dark.svg') no-repeat right 12px center, var(--white);
  }
}
