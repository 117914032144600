.dashlet {
  display: flex;
  flex-direction: column;
  height: 100%;
  .content {
    flex: 1;
  }
}

.total {
  font-weight: bold;
  font-size: 35px;
  padding: 3px;
  padding-top: 12px;
}

.helper-text {
  font-size: 13px;
  opacity: 0.5;
  padding-top: 6px;
  margin-bottom: 6px;
}

