h2.title {
  margin-bottom: 5px!important;
  padding: 1px!important;
}

h3.artist {
  margin-bottom: 5px!important;
  padding: 1px!important;
}

.content {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  min-width: 800px;
  overflow: auto;
  gap: 1rem;
}

@media (--desktop) {
  .content {
    min-width: 900px;
  }
}

@media (--mobile-only) {
  .content {
    flex-direction: column;
    min-width: auto;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
}
.close {
  cursor: pointer;
}
.navigation {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.file-controls {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  button {
    flex: 1;
  }
}

.recording {
  flex: 0.35;
  background-color: var(--off-white);
  padding: 1rem;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 1.5rem;
      font-size: 14px;
      h4 {
        padding: 1px;
        color: var(--container-dark-grey);
      }
      p {
        margin: 0;
      }
    }
  }
}
.recording-form {
  flex: 0.65;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;


  h3 {
    border-bottom: solid 1px var(--selection-grey);
  }
}
.ownership {
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.file-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  padding-left: 0.5rem;
  li {
    padding: 0.1rem;
  }
}

.file-list--row {
  max-width: 500px;
  h4 {
    display: flex;
    gap: 1rem;
  }
  p {
    color: var(--subtle-grey);
    padding: 0;
    margin: 0;
  }
}



.new-performance {
  padding: 0.5rem;
  background-color: var(--off-white);
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.new-performance-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding : 0.5rem;
}

.new-performance-controls svg {
  display: block;
}

.active {
  background-color: var(--forest-green);
}
