.thumbnail {
  height: 100%;
  width: 100%;

  img,
  .album-art {
    height: 100%;
    width: 100%;
  }

  .album-art {
    background-color: var(--off-white);
    opacity: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 80%;
      width: 80%;
      path:global.active {
        fill: none;
      }
      path:global.inactive {
        fill: var(--selection-grey);
      }
    }
  }
}
