.notification {
  display: flex;
  justify-content: center;
  > div {
    display: inline-block;
    background-color: var(--white);
    color: var(--black);
    padding: 1.35rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: var(--input-border-radius);
    a {
      text-decoration: underline ! important;
    }
  }
}
