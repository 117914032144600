td.thumbnail {
  width: 85px;

  & > div {
    height: 50px;
    width: 50px;
  }
}
.status-header {
  text-align: center;
}
.toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  align-items: center;
  gap: 1rem;
}

.search {
  flex: 1;
  @media (--mobile-only) {
    width: 100%;
  }
}

.missing {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  @media (--mobile-only) {
    width: 100%;
    flex-direction: column;
    a {
      width: 100%;
    }
  }
}

@media (--mobile-only) {
  .toolbar {
    flex-direction: column;
    min-width: auto;
  }
  .missing {
    flex-direction: column;
    min-width: auto;
  }
}
