:global {
  button, .btn, .btn--primary, .link--edit {
    background: var(--primary);
    border: 1px solid var(--primary);
    border-radius: var(--input-border-radius);
    font-family: var(--base-font-family);
    letter-spacing: 0.02em;
    color: var(--white);
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    height: 36px;
    min-width: 150px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-weight: var(--normal);
    line-height: 14px;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .btn--inline {
    display: inline-block;
  }
  button:hover, .btn:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .btn--secondary {
    background: var(--secondary);
    border: 0.8px solid var(--secondary);
    color: var(--black);
  }

  .btn--secondary-inversed, .link--edit {
    background: transparent;
    border: 0.8px solid var(--black);
    color: var(--black);
  }
  .btn--secondary-inversed.btn--secondary {
    background: transparent;
    border: 0.8px solid var(--white);
    color: var(--white);
  }
  .btn--primary:disabled {
    background-color: var(--disabled-grey);
    border: solid 0.8px var(--disabled-grey);
    color: var(--subtle-grey);
    &:hover {
      opacity: 1;
      cursor: not-allowed;
    }
  }
}
