:global {
  .form {
    margin-bottom: 1.2rem;
  }

  .form-edit {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 10px;
    .form-edit-content {
      flex-basis: 98%;
      max-width: 98%;
      section {
        margin-top: 10px;
        padding-top: 10px;
        border-top: solid var(--disabled-grey) 1px;
      }
      section:first-child {
        border: none;
      }
    }
    .form-edit-controls {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media(--tablet) {
        margin-bottom: 10px;
      }
      @media(--mobile-only) {
        flex-direction: column;
      }
      h2 {
        flex: 1;
      }
    }
    .row {
      @media(--tablet) {
        align-items: flex-start;
      }
    }
    @media(--mobile-only) {
      flex-direction: column;
      display: flex;
    }
  }

  .citizenships, .residencies, .addable-field-list {
    .form__label {
      > span {
        @media(--tablet) {
          display: none;
        }
      }
    }
    /* hide headers aside from the first entry in the form */
    > div:first-child .form__label {
      > span {
        display: block;
      }
    }
  }

  .form--flex {
    display: flex;
    align-items: flex-end;
    margin: 0 0 1.2rem 0;

    .form__label, .btn {
      margin: 0 1rem 0 0;
    }

    .form__input {
      margin-top: .12rem;
    }
  }

  .form__section--action {
    margin-top: 14px;
    margin-bottom: 14px;
    justify-content: flex-end;
    @media(--mobile-only) {
      flex-direction: column-reverse;
      > * {
        margin-bottom: 5px;
      }
    }
  }
  .form-edit-controls .form__section--action {
    @media(--mobile-only) {
      flex-direction: row;
    }
  }

  .form__section--flex {
    display: flex;

    .btn {
      flex: 1;
      white-space: nowrap;

      @media (--tablet) {
        flex: 0 1 auto;
      }

      &--flex {
        flex: 1;
      }
    }
  }

  .form__fieldset {
    position: relative;
    border: 0;
    padding: 0;
    clear: both;
  }

  .form__fieldset {
    position: relative;
    border: 0;
    padding: 0;
    clear: both;
    margin-top: 10px;
    legend {
      font-weight: var(--bold);
    }
  }

  .form__fieldset--group {
    background: none;
    border: 0;
    margin-top: 10px;
    padding: 1rem 0;
  }

  .form__fieldset--border {

    padding: 2rem;

    legend {
      font-size: 2rem;
      font-weight: var(--bold);
    }

    .form__fieldset__title {
      margin: -1.5rem 0 2rem 0;
      font-size: 2rem;
      color: inherit;
    }
  }

  .legend--with-link {
    max-width: 200px;
    height: 3rem;
    @media (--tablet) { max-width: 350px; }
    @media (--desktop) { max-width: 500px; }
    @media (--hd) { max-width: 600px; }

    a {
      font-size: 1.6rem;
      padding: 0rem 0.25rem 0rem 0.25rem;
      position: relative;
      top: 0;
      position: absolute;
      right: 1rem;
    }
  }

  .form--summary {
    .form__label {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: var(--subtle-grey);
        border-width: 1px 0 1px 1px;
        text-transform: none;

        font-weight: var(--bold);
        padding: 1.2rem;
        flex: 0 0 45%;
        display: block;
        @media (--tablet) { flex: 0 0 15%; }
      }
    }

    .form__label {
      margin-bottom: .6rem;
    }
    .form__input {
      margin-top: 0;
      width: auto;
      border-left: 0;

      &[disabled] {

      }
    }
  }
}
