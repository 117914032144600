:global {
  @keyframes :global(sk-stretchdelay) {
    0%, 40%, 100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
    }
  }
  .spinner {
    margin: 100px auto;
    width: 50px;
    height: 44px;
    text-align: center;
    font-size: 10px;
    background: none;

    & > div {
      background-color: var(--white);
      height: 100%;
      width: 0.3rem;
      display: inline-block;

      -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner__rect2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }

    .spinner__rect3 {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s;
    }

    .spinner__rect4 {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }

    .spinner__rect5 {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }

  .spinner--minimal {
    margin: 0;
    height: 16px;
    position: relative;
    z-index: 2;
  }

  .searchable-list__loading {
    .spinner--minimal {
      bottom: 2px;
    }
  }

  .btn--primary {
    .spinner--minimal {
      & > div {
        background-color: var(--white);
        opacity: 0.4;
      }
    }
  }

  .btn--secondary, .search__submit {
    .spinner--minimal {
      & > div {
        background-color: var(--text-black);
        opacity: 0.5;
      }
    }
  }

  .spinner--inline {
    position: static;
    height: 18px;
    margin-top: 2px;
  }

  .simple .spinner div {
    background-color: var(--black);
  }
}
