.login {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: stretch;
  color: var(--dialog-color);
  h1,
  h2,
  h3,
  h4,
  p {
    text-align: center !important;
  }
  display: flex;

  p {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  a {
    text-decoration: underline !important;
  }
  @media (--mobile-only) {
    justify-content: center;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--disabled-grey);
    margin: 30px 0;
    padding: 0;
  }
  h3 {
    margin: 0 !important;
    padding: 0 !important;
    font-size: var(--base-font-size) !important;
  }
}

.brand-highlight {
  display: none;
}
@media (--tablet) {
  .brand-highlight {
    flex: 1;
    background: linear-gradient(transparent, 60%, rgba(0, 61, 255, 0.5)), url('/assets/login-brand-background.jpg');
    background-size: cover;
    display: block;
    position: relative;
  }
  .brand-highlight-details {
    position: absolute;
    bottom: 2rem;
    right: 10rem;
    color: var(--white);
    p {
      font-size: var(--small-font-size);
      line-height: var(--small-font-size);
      text-align: right !important;
      margin: 0 !important;
      padding: 1px !important;
    }
    .artist {
      font-weight: bold;
    }
  }
}

.login-form {
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-basis: 33%;
  @media (--mobile-only) {
    flex-basis: 90%;
  }
  @media (--tablet) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.first-visit {
  padding-bottom: 20px;
}
