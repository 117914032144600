.header {
  display: flex;
  align-items: center;
  margin-bottom: 0!important;

  .thumbnail {
    width: 150px;
    height: 150px;
    margin: 30px 30px 30px 0;
  }
  h1 {
    margin-top: 0 !important;
  }
}
