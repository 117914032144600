.performance {
  display: grid;
  grid-template-columns: 2fr 1fr;
  max-width: 500px;
  flex-direction: row;
  border-radius: var(--compact-border-radius);
  padding: 0.5rem;
  align-items: ceneter;

  @media (--mobile-only) {
    max-width: 100%;
  }
}

.active {
  background-color: var(--forest-green);
}

.role-code {
  font-weight: var(--bold);
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.role-code a {
  display: flex;
  flex-direction: row;
  align-items: center;
}
