:root {
  --form-elements-height: 4.8rem
}

:global(.search-header) {
  position: relative;
  @media (--mobile-only) {
    > div {
      display: none;
    }
  }
}

.unified-search {

  z-index: 20;
  width: 50rem;
  background: var(--dialog-background-color);
  border: solid 1px var(--disabled-grey);
  border-radius: 24px;
  padding: 0 20px 0 20px;
  position: relative;
  /* search form styles */
  @media (--mobile-only) {
    width: 90%;
  }

  .search-form {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: var(--form-elements-height);


    .icon {
      height: 100%;
    }

    .icon-x:hover {
      cursor: pointer;
    }

    .search-form-input {
      margin: 0;
      height: var(--form-elements-height);
      border: none;
    }

    .search-form-input:focus {
      outline: none;
    }

    .search-form-submit-button {
      background: var(--subtle-grey);
      color: var(--dialog-color);
      border-style: solid;
      border-color: rgb(68, 67, 73);
      border-width: 0 0 3px 0;
      border-radius: 0 2px 2px 0;
      display: block;
      padding: 0 2rem;
      text-decoration: none;
      position: relative;
      box-sizing: border-box;
      height: var(--form-elements-height);

      &::before {
        content: "\e91a";
        font-family: 'icomoon';
        font-size: 2.2rem;
        line-height: 2;
      }
    }
  }

  /* suggestions container styles */

  .suggestions-container {
    position: absolute;
    width: 100%; /* FIXME */
    left: 0px; /* FIXME */
    z-index: 20;
    background: var(--dialog-background-color);
    color: var(--dialog-color);
    box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.05);
    border-top: none;
    text-align:left;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    :global(.spinner.spinner--minimal) {
      bottom: 50%;
      margin-bottom: -8px;
    }
  }

  &.showing-suggestions {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    .suggestions-container {
      display: block;
    }
  }

  .loading-container {
    padding: 2rem;
    :global(.spinner) > div {
      background-color: var(--black);
    }
  }

  .empty-result-container {
    text-align: center;
    padding: 2rem;

    .description {
      font-weight: var(--bold);
      padding: 5px;
    }
  }

  .loading-container {
    padding: 2rem;
  }
}

.overlay {
  z-index: 10;
  position: fixed;
  top: 0px; /* FIXME */
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.7);
  opacity: 1;
  width: 100%;
  transition: opacity 0.2s;
  @media (--mobile-only) {
    display: none;
  }
}

.overlay-hidden {
  opacity: 0;
  width: 0;
  transition: opacity 0.2s, width 0.2s 0.2s;
}
