:app-root {
  main:before {
    background-image: var(--recording-background-gradient);
    background-size: var(--gradient-background-size);
  }
}

td.thumbnail {
  width: 85px;

  & > div {
    height: 50px;
    width: 50px;
  }
}
