:global {
  /* Min Side */
  .section__header, .container__header {
    display: block;
    padding-top: 0;
    text-align: center;
  }

  .section-header__action--disabled {
    flex-direction: column;
    flex: 1 0 100%;
    text-align: center;
    align-items: center;
    order: -1;
    padding: 0 5rem 1rem 5rem;
    margin-bottom: .6rem;
    @media (--desktop) {
      flex-direction: row;
      flex: 0 0 auto;
      order: 0;
      padding: 0;
    }
    
    .icon {
      font-size: 3.6rem;
      vertical-align: top;
      margin-right: 5px;
      @media (--desktop) {
        font-size: 3rem;
      }
    }
  }

  .section-header--with-toggle {
    header {
      flex: 1 0 100%;
      margin-bottom: 1rem;
      @media (--desktop) {
        flex: 1 0 auto;
        margin-bottom: 0;
      }
    }
    
    .section-header__action {
      flex: 1 0 auto;
    }
  }

  .section-header__toggle {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    @media (--desktop) {
      margin-left: 2rem;
      flex: 0 1 100%;
    }
  }
}
