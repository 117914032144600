:global {

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: calc(100% + 2rem);
    margin: 0 -1rem 1.2rem -1rem;
    text-align: left;
    table-layout: fixed;
    @media (--desktop) { min-width: 100%; width: auto; margin: 0 0 1.2rem 0; }

    thead,
    tbody,
    tfoot {
      td, th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    thead {
      th {
        font-weight: var(--normal);
        text-align: left;
        color: var(--subtle-grey);
        letter-spacing: 0.04em;
        font-size: 13px;
        text-transform: uppercase;
      }
    }
    td {
      padding-top: 18px;
      padding-bottom: 18px;
      padding-left: 2px;
      padding-right: 2px;
      a {
        font-weight: var(--medium);
        text-decoration: underline !important;
      }
      h1, h2, h3, h4 {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    tbody > tr {
      &:hover {
        @media (--tablet) { opacity: 0.8 }
      }
    }
    tbody > tr:global(.actionable) {
      cursor: pointer;
    }
    tbody > tr:hover {
      @media (--tablet) {
        background-color: color(var(--disabled-grey) a(30%));
      }
    }

    /*** Disabled rows in table ***/
    .table__row--disabled td {
      color: var(--disabled-grey);
      cursor: not-allowed;
      @media (--desktop) {
        background: var(--off-white);
      }
    }
  }

  caption {
    text-align: left;
    font-weight: var(--medium);
    margin: 0 0 1rem .25rem;
  }

  /* For style guide - put here to avoid cascade issues (styleguide lower in cascade) */
  .table--simple {
    min-width: 0;

    th {
      border: 0;
    }

    tbody {
      & > tr {
        background: none;
      }

      td:first-child {
        border-left: 0;
      }

      td:last-child {
        border-right: 0;
      }
    }
  }
  .table-footer {
    display: flex;
    align-items: center;
    @media(--mobile-only) {
      flex-direction: column;
      align-items: center;
      > nav {
        margin-top: 10px;
      }
    }
    margin-top: 10px;
    p {
      line-height: 45px;
      margin-right: 10px;
    }
    nav {
      display: inline-block;
      flex: 1;
    }
  }
}
