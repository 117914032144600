.stepper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 5px;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  top: 6px;
  left: 0;
  z-index: 3;

  @media (--tablet) {
    text-align: center;
  }

  .stepper__text {
    text-align: center;
    opacity: 0.5;
    @media (--tablet) {
      color: inherit;
    }
  }
}
