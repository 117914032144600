:global {
  /*** Fade in ***/
  /* prettier-ignore */
  @keyframes :global(fadeIn) {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* prettier-ignore */
  @keyframes :global(fadeOut) {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .flash-message-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    transition: all 0.25s ease;
    position: fixed;
    top: 20px;
    @media (--mobile-only) {
      top: 84px;
    }
    width: 100%;
    z-index: 10001;

    &--full-width {
    }

    &--static {
      position: static;
    }
  }

  .pull .flash-message-wrapper {
    left: 0;
  }

  .flash-message {
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
    max-width: 100%;
    justify-content: center;
    margin-top: 0;
    font-weight: var(--bold);
    min-height: 3.2rem;
    min-width: 40%;
    border-radius: var(--input-border-radius);
    animation: fadeIn ease 1s;

    &.exiting {
      animation: fadeOut ease 1s;
    }

    & > p {
      flex: 1;
      color: var(--white);
    }

    a {
      color: var(--white);
      text-decoration: underline;
    }

    &__close {
      align-self: flex-end;
      font-size: 1.5rem;
    }
  }

  .flash-message--error {
    background: var(--error);

    .btn {
      background: color(var(--error) shade(25%));
    }
  }

  .flash-message--success {
    background: var(--success);

    .btn {
      background: color(var(--success) shade(25%));
    }
  }

  /* Ingen filer er lest inn i perioden message */
  .message {
    clear: both;
    margin: 2rem 0;
    font-style: italic;
    color: var(--off-grey);
  }
}
