 :global {
  /* Min Side */
  .box {
    background: var(--dialog-background-color);
    color: var(--dialog-color);
    a:not(.btn) {
      color: var(--dialog-color);
    }
    padding: 1rem;
    width: 100%;
    @media (--tablet) {
      padding: 2rem;
      width: 500px;
      box-shadow: 0 1px 3px color(var(--off-grey) a(30%));
      margin: auto;
    }

  }

  .box--login {
    padding: 1.5rem;
    @media(--tablet) {
      width: 400px;
    }
  }

  .box--welcome {

  }

  .box__header {
    text-align: center;
    padding: 1rem 3rem 0 3rem;
  }

  .box--overlay {
    border: 0;
    margin: 0 auto;
    box-shadow: 0 2px 5px 0 color(var(--black));
    border-radius: var(--container-border-radius);
    max-height: 98%;
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .box--meta {
    background: var(--silver);
  }
}
