:global {
  .tabs__nav {
    list-style-type: none;
    margin: 0;
    margin-top: 5px;
    padding: 0;

    position: relative;
    z-index: 1;
    display: flex;
    @media(--mobile-only) {
      .badge {
        display: none;
      }
    }
  
    &__item {
      position: relative;
      flex-direction: column;
      text-align: center;
      padding: 0;
      background: none;
      color: var(--black);
      border: none;
      a {
        border: solid 1px color(var(--container-dark-grey) a(90%));
        text-decoration: none;
        font-weight: var(--bold);
        padding: 1rem 0;
        flex: 1 0 auto;
        display: block;
        background: var(--white);
        color: var(--subtle-grey);
      }
      &:first-child a {
        border-radius: var(--container-border-radius) 0 0 0;
      }
      &:last-child a {
        border-radius: 0 var(--container-border-radius) 0 0;
      }
    }

    &__item:hover a {
      opacity: 0.9;
    }
    &__item a.active {
      background: var(--container-background-color);
      color: var(--white);
      &::after {
        content: none;
      } 
    }
  }

  .tabs__nav--flexible {
    display: flex;
    flex-wrap: nowrap;
    
    @media (--desktop) { display: inline-flex; margin: 0; }
    
    .tabs__nav__item {
      flex: 1 0 auto;
      @media (--tablet) { flex: 0 0 auto; }
      
      & > a {
        padding-left: 1rem;
        padding-right: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media (--tablet) { min-width: 150px; }
      }
    }
  }
}
