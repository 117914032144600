:global {
  .search {
    padding: 1.2rem 0;
  }

  .search__form {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: stretch;
    max-width: 500px;
    margin: 2rem;
    @media (--tablet) {
      margin: auto;
    }

    .form__label {
      flex: 1 0 auto;
      margin: 0;
    }

    .form__input {
      margin-top: 0;
      flex: 0 1 auto;
      outline: none;
      @media (--tablet) {
        flex: 1 0 auto;
      }
    }

    .search__submit {
      border-radius: 0 22px 22px 0px;
      width: 50px;
      min-width: 50px;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      height: auto;
      margin: 0;
      padding-left: 10px;
      path, line {
        stroke: var(--white);
      }
    }
  }

  .search__form--left {
    justify-content: flex-start;
    margin: 0;
  }

  /* Filter search */
  .search--filter {
    padding: 0;

    .search__form {
      max-width: 400px;
    }

    /* .form__input {
      padding: .75rem;
    } */

    .search__submit::before {
      line-height: inherit;
    }
  }

  /* Global search with select menu */

  .search__form--global {
    align-items: center;
    max-width: 600px;
    margin: auto;
    flex-wrap: wrap;


    .form__label {
      margin: 0 0 1.2rem 0;
      @media (--tablet) {
        margin: 0;
      }
    }

    .form__label:first-child {
      flex: 1 0 100%;
      @media (--tablet) {
        flex: 0 0 auto;
      }

      .select {

        @media (--tablet) {
          margin: 0;
          border-right: 0;
          border-radius: 3px 0 0 3px;
        }
      }
    }

    .form__label:nth-child(2n) {
      flex: 1 0 100%;
      @media (--tablet) {
        flex: 1 0 auto;
      }

      .form__input {
        max-width: none;
        height: 4.8rem;
      }
    }

    .search__submit {
      height: 4.8rem;
      flex: 1 0 100%;
      @media (--tablet) {
        flex: 0 0 auto;
      }
    }
  }
}
