.page-footer {
  border-top : solid 1px var(--container-dark-grey);
  color: var(--almost-grey);
  display: flex;
  margin-top: 2.3rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  justify-content: space-between;
  text-transform: uppercase;
  a {
    text-decoration: underline !important;
  }
}
