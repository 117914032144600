:global {
  .tabs__bar {
    display: flex;
    align-items: center;
    @media (--mobile-only) {
      flex-direction: column-reverse;
      align-items: stretch;
    }
  }

  .tabs__download {
    padding-left: 2rem;
    margin-left: auto;
  }
}
