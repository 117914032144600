:global {
  /* override styles for the simple headerless view used for login and such */
  .simple {
    main {
      display: flex;
      justify-content: center;
      h1, h2, h3, h4 {
        text-align: center;
        margin: 10px;
      }
      h3 {
        margin-top: 10px;
      }
      h1 {
        margin-bottom: 30px;
      }
      form {
        margin-top: 20px;
      }
      p {
        padding: 8px;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: left;
      }
      button {
        margin-top: 9px;
      }
      svg path {
        fill: black;
      }
      .simple-branding {
        padding-bottom: 30px;
      }
      .simple-link {
        text-align: center;
        padding: 8px;
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
