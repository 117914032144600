nav.left-side-navigation {
  display: block;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;

  .selected-nav-item {
    position: relative;
    display: block;
    background-color: var(--dialog-background-color);
    color: var(--dialog-color);
    padding: 1.2rem 0.75rem;
    border-radius: 4px;

    &::after {
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
      right: 10px;
      display: inline-block;
      content: "\E90C";
      font-family: 'minside-icons';
      font-size: 1rem;
      -webkit-transition: all .25s ease;
      transition: all .25s ease;
    }
  }

  .mobile-help-text {
    margin-bottom: 0.6rem;
  }

  .dropdown-container {
    ul {
      list-style-type: none;
    }
  }

  &.showing-menu {
    .selected-nav-item {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  :global(.categorize-navigation-menu) {
    :global(.category-title) {
      color: var(--subtle-grey);
      border-bottom: solid 1px var(--subtle-grey);
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  &.mobile-view {
    .dropdown-container {
      overflow: hidden;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.26s ease;
      position: absolute;
      top:100%;
      width: 100%;
      z-index:9999;
      background-color: var(--dialog-background-color);

      ul {
        border: 0;
        margin: 0;
        padding: 0;

        li {
          padding: 0 0 0 0;
          display: block;
          width: 100%;

          a {
            margin: 0;
            &:active, &:global(.active) {
              text-decoration: none;
              color: var(--dialog-color);
              path {
                fill: var(--dialog-color);
              }
            }
          }
        }
      }

      :global(.categorize-navigation-menu) {
        :global(.category-title) {
          padding: 1rem 3rem;
        }

        > ul li a {
          padding: 1.3rem 6rem;
        }
      }
    }

    &.showing-menu {
      .dropdown-container {
        transform: scaleY(1);
        border-style: solid;
        border-width: 0 1px 1px 1px;
        border-color: var(--silver);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
      }
    }
  }
}
