:global {
  .searchable-list {
    display: block;
    position: relative;
    background-color: var(--white);
    border-radius: 2px;
    border: solid 1px var(--disabled-grey);
    text-align: left;
    padding: 0 0.25rem;
    vertical-align: middle;

    &--fluid {
      min-width: 0;
    }

    &__heading {
      position: relative;
      height: 30px;
      padding: 7px 30px 7px 5px;
      cursor: pointer;
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;

      &:before {
        content: '';
        background-image: url('img/chevron-down.svg');
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 12px;
        position: absolute;
        height: 20px;
        width: 12px;
        right: 3px;
      }
    }

    &--open {
      .searchable-list__heading:before {
        transform: rotate(180deg);
      }

      .searchable-list__dropdown {
        display: block;
      }
    }

    &--disabled {
      background-color: var(--disabled-grey);
      border-color: var(--disabled-grey);

      .searchable-list__heading {
        cursor: not-allowed;
        outline: none;

        &:before {
          background-image: none;
        }
      }

      .searchable-list__dropdown {
        display: none;
      }
    }

    &__dropdown {
      position: absolute;
      left: 0;
      display: none;
      background-color: var(--white);
      border-radius: 2px;
      border: var(--menu-border);
      box-shadow: var(--menu-box-shadow);
      z-index: 2;
      width: 100%;
      @media (--tablet) {
        width: auto; /* Let the dropdown be as wide as it's longest child */
      }

      .searchable-list__search {
        background: url('/assets/icons/icon-magnifying-glass.svg') right 1rem center no-repeat;
        background-size: 1.8rem;
        width: 96%;
        margin: 0.5rem auto;

        &--dirty {
          background-image: none;
        }
      }
    }

    &__dropdown ul {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 200px;
      overflow: auto;
      font-weight: var(--normal);

      li.searchable-list__option {
        padding: 5px;
        white-space: nowrap;

        &--active,
        &:hover {
          color: var(--white);
          background-color: var(--highlight-color);
          cursor: pointer;
        }
      }
    }

    /*** Below search list for search results e.g. discography search in manual matching ***/
    &--below-search {
      border: 0;
      padding: 0;
      display: block;

      .searchable-list__dropdown {
        border-top: 0;
        width: 100%;
      }
    }

    &__delete {
      color: var(--disabled-grey);
      position: absolute;
      right: 1.5rem;
      top: 50%;
      margin-top: -1rem;
      font-size: 110%;
    }
  }

  .searchable-list--in-form-field {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    border: 0;
    @media (--tablet) {
      width: calc(100% - 140px);
      left: 137px;
    }
  }

  .searchable-list {
    padding: 0;

    &__heading {
      height: auto;
      padding: 1.2rem 2.3rem 1.2rem 0.75rem;

      &:before {
        background-image: url(/assets/icons/icon-caret-down-dark.svg);
        right: 1rem;
      }
    }

    &--disabled {
      border-color: var(--disabled-grey);
      background: var(--disabled-grey);
      color: var(--subtle-grey);
    }

    &--full-width {
      max-width: none;
    }
  }

  .searchable-list__dropdown {
    width: 100%;
  }

  .searchable-list__dropdown ul {
    li.searchable-list__option {
      &--active,
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .searchable-list__loading {
    padding: 1rem 0.5rem;
    position: relative;
  }

  .searchable-list--for-input {
    width: 100%;
    max-width: none;
    top: -1px;
    border: 0;
    display: block;
  }

  /* Ember power select */
  .ember-power-select-search {
    padding: 5px;
  }

  .ember-power-select-trigger,
  .ember-power-select-trigger--active,
  .ember-power-select-trigger:focus {
    border: 0;
    line-height: var(--base-line-height);
  }

  .ember-power-select-dropdown {
    border: var(--menu-border);
    box-shadow: var(--menu-box-shadow);
    border-top: none;
    border-radius: 0;
    color: var(--black);
  }

  .ember-power-select-trigger {
    border-radius: 1px;
    padding: 0;
  }

  .ember-power-select-status-icon {
    border-width: 8px 5px 0;
    border-color: var(--disabled-grey) transparent transparent;
    /* right: 5px; */
  }

  .ember-power-select-search-input {
    border: 1px solid var(--disabled-grey);
    background: var(--white) url('/assets/icons/icon-magnifying-glass.svg') right 1rem center
      no-repeat;
    background-size: 1.8rem;
    border-radius: 0;
    /* width: 100%;
      font-size: inherit; */
    padding: 1.2rem 0.75rem;
    box-sizing: border-box !important;
  }

  .ember-power-select-trigger[aria-disabled='true'] .ember-power-select-search-input {
    border-color: var(--disabled-grey);
    background: var(--disabled-grey);
  }

  .ember-power-select-options {
    li {
      padding: 5px;
    }
  }

  .ember-power-select-option[aria-current='true'] {
  }
}
