.ownership {
  display: flex;
  max-width: 600px;
  flex-direction: column;
  gap: 1rem;

  @media (--mobile-only) {
    max-width: 100%;
  }
}

.active {
  background-color: var(--forest-green);
}

.dates {
  display: flex;
  gap: 5rem;
}
.dates > div {
  flex: 1;
}
