:app-root {
  main:before {
    background-image: var(--recording-background-gradient);
    background-size: var(--gradient-background-size);
  }
}

.alternative-list {
  margin: 0.1rem;
  padding: 0;
  list-style-type: none;
}

.recording-section h3 {
  margin-top: 0!important;
}

@media(--tablet) {
  .recording-info {
    display: grid;
    grid-template-columns: 150px 1fr;
    align-items: center;
    gap: 0.5rem;
    h4 {
      text-align: right!important;
    }
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
