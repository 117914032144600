:global {
  /* Min Side */

  /*** lists ***/
  %no-list, .no-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  /* Clearfix */
  .clear:after {
    content: "";
    display: table;
    clear: both;
  }

  .text--left {
    text-align: left!important;
  }

  .text--right, .text--right-always {
    text-align: right!important;
  }

  .text--center, .text--center-always {
    text-align: center!important;
  }

  .text--light {
    color: var(--off-grey)!important;

    a {
      color: inherit;
    }
  }

  .text--thin {
    font-weight: 300!important;
  }

  .text--normal {
    font-weight: 400!important;
    font-style: normal;
  }

  .text--semibold {
    font-weight: 600!important;
  }

  .text--bold {
    font-weight: 700!important;
  }

  .text--italic {
    font-style: italic;
  }

  .text--small {
    font-size: 1.2rem!important;
    line-height: 1.5rem;
  }

  .text--medium {
    font-size: 1.4rem!important;
  }

  .text--upper {
    text-transform: uppercase;
  }

  .text--error {
    color: var(--gramo-red);
    margin-top: -0.5rem;
  }

  .text--hidden {
    visibility: hidden;
    display: block;
    height: 0;
  }

  .text--help {
    position: relative;
    top: -0.5rem;
    @media (--tablet) {
      top: 0.5rem;
    }

    &--hidden {
      display: none;
    }
  }

  .heading--sub {
    margin-top: 1.2rem;
    margin-bottom: .6rem;
  }

  /* Strike-through for diff. */
  .text--strike {
    text-decoration: line-through;
  }

  .row--with-border::before,
  .row--with-border::after {
    content: '';
    height: 1px;
    width: calc(100% - 2rem);
    margin-left: 1rem;
    border-bottom: 1px solid var(--silver);
    margin-bottom: 1rem;
  }

  /* flexbox overrides */

  .col--no-flex {
    display: block!important;
  }

  .align--vertical-center {
    align-self: center;
  }

  .justify--center {
    justify-content: center;
  }

  .align--items-end {
    @media (--tablet) {
      align-items: flex-end;
    }
    &--mobile {
      align-items: flex-end;
    }
  }

  .align--items-start {
    @media (--tablet) {
      align-items: flex-start;
    }
    &--mobile {
      align-items: flex-start;
    }
  }

  .align--self-end {
    @media (--tablet) {
      align-self: flex-end;
    }
    &--mobile {
      align-self: flex-end;
    }
  }

  .align--self-start {
    @media (--tablet) {
      align-self: flex-start;
    }
    &--mobile {
      align-self: flex-start;
    }
  }

  /* margin overrides */

  .no-margin {
    margin: 0!important;

    &--left {
      margin-left: 0!important;
    }

    &--right {
      margin-right: 0!important;
    }

    &--top {
      margin-top: 0!important;
    }

    &--bottom {
      margin-bottom: 0!important;
    }
  }

  /* padding overrides */

  .no-padding {
    padding: 0!important;

    &--left {
      @media (--desktop) { padding-left: 0!important; }
    }

    &--right {
      @media (--desktop) { padding-right: 0!important; }
    }

    &--top {
      @media (--desktop) { padding-top: 0!important; }
    }

    &--bottom {
      @media (--desktop) { padding-bottom: 0!important; }
    }
  }

  .hide-labels > .row:not(:first-child):not(:nth-child(2)) {
    .form__label span {
      visibility: hidden;
      height: 0;
      overflow: hidden;
      display: block;
    }
  }

  /* Truncated text */
  .truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (--desktop) {
      max-width: 100px;
    }
  }
  .show-on--mobile {
    @media (--tablet) { display: none; }
  }

  [class*="--no-wrap"] {
    white-space: nowrap;
  }

  .hide {
    display: none;
  }

  .pending {
    opacity: 0.5;
  }

  /* For print */
  .print-header {
    display: none;
  }

  /* For prototype (highlight clickable areas) */
  .click-here {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      border: 5px solid var(--gramo-red);
      opacity: 0.5;
      right: -15px;
      top: -5px;
    }
  }

  .row--clickable {
    cursor: pointer;
  }

  .hidden {
    display: none;
  }
}
