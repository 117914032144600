.nav {
  display: flex;
  flex-direction: column;
  width: auto;
  
  transform: translateX(1000px);

  background: var(--dialog-background-color);
  color: var(--dialog-color);
  border-radius: 10px 10px 10px 10px;
  box-shadow: var(--menu-box-shadow);

  position: fixed;
  z-index: 9000;
  right: 20px;
  top: 64px;
  opacity: 0;
  transition: opacity 0.2s ease-in, transform 0s 0.2s;
  @media(--tablet) {
    overflow: visible;
  }
  &.visible {
    visibility: visible;
    transform: translateX(-12px);
    @media(--mobile-only) {
      transform: translateX(0px);
    }
    transition: opacity 0.2s ease-in;
    opacity: 1;
  }


  @media(--tablet) {
    &:after {
      content:"";
      position: absolute;
      right: 6px;
      top: -8px;
      width: 3px;
      height: 3px;
      border-style: solid;
      border-width: 0 13px 13px 13px;
      border-color: transparent transparent var(--dialog-background-color) transparent;
      z-index:9998;
    }
  }
  ul.site-menu {
    &:not(:first-child) {
      border-top: 0.5px solid #444444;
    }
    @media (--mobile-only) {
      border-top: none;
    }
    list-style-type: none;
    width: 100%;

    padding: 0;
    margin: 0;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  li {
    display: block;
  }
  li.list-header {
    opacity: 0.5;
    font-weight: var(--medium);
    @media (--mobile-only) {
      color: rgba(255,255,255,0.6);
      padding-bottom: 2rem;
    }
    padding-top: 0.5rem;
  }
  a, li.list-header {
    text-decoration: none;
    display: block;
    padding-left: 1.5rem;
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
    padding-right: 4rem;
  }
  @media(--tablet) {
    a:hover {
      background-color: color(var(--disabled-grey) a(30%));
    }
  }
  a {
    color: var(--dialog-color);
  }
  li a:global(.active) {
    background-color: var(--menu-selected-background);
  }
  /* the mobile menu is significantly different so the major changes are here */
  @media (--mobile-only) {
    left: 0px;
    right: 0px;
    width: 100%;
    bottom: 0px;
    overflow: auto;

    background-color: var(--base-background-color);
    border-radius: 0;
    color: #FDFDFD;
    font-size: 26px;
    font-weight: normal;
    li {
      :global(.menu-text) {
        padding-left: 1rem;
      }     
      height: 50px; 
    }
    a {
      color: var(--white);
      padding: 0.5rem ! important;
      padding-left: 1rem ! important;
      padding-top: 1rem ! important;
      padding-bottom: 1rem ! important;
    } 
    li a:global(.active) {
      font-weight: 600;
    }
    
    svg {
      width: 20px;
    }
    svg path {
      fill: var(--white);
    }
    svg path:global(.nofill) {
      fill: none;
    }
    svg path:global(.stroke) {
      stroke: var(--white);
    }
    svg path:global(.inactive) {
      visibility: visible;
    }
    svg path:global(.active) {
      visibility: hidden;
    }
    a:global(.active) svg path:global(.active) {
      visibility: visible;
    }
    a:global(.active) svg path:global(.inactive) {
      visibility: hidden;
    }

    li.list-header {
      padding-top: 1rem;
      font-size: 1.6rem;
    }

    ul.other-menu, ul.logout-menu {
      font-size: 1.5rem;
      li {
        padding: 0.3rem;
        padding-left: 1.2rem;
      }
      li a {
        padding: 0.3rem;
      }
    }
    ul.other-menu {
      padding-bottom: 0;
      padding-top: 0.5rem;
    }

    ul.logout-menu {
      padding: 0;
      margin-bottom: 20px;
    }
  }

}

:global(.impersonating) .nav {
  top: 104px;
}

/* the mobile menu has the profile name and icon inside the menu */

.menu-profile-header {
  width: 100%;
  height: 80px;
  background-color: var(--ocean-blue);
  color: white;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  margin-bottom: 20px;
  a {
    text-decoration: underline;
  }
  @media(--mobile-only) {
    border-radius: 0;
  }
}

/* override the border color since we are showing the avatar in the menu itself */
.profile-avatar div:global(.profile-avatar) {
  border: solid white 2px;
}

.profile-name {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  flex: 1;
}

.profile-role-name {
  font-weight: 600;
}
