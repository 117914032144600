:global {
  .row {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    position: relative;
    @media (--mobile-only) {
      flex-direction: column;
    }
  }

  .col-xs, .col-sm, .col-md, .col-lg, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4,
  .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11,
  .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6,
  .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1,
  .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8,
  .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3,
  .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10,
  .col-lg-11, .col-lg-12 {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @media(--mobile-only) {
      flex-basis: 100% ! important;
      max-width: 98% ! important;
    }
  }

  .col-xs, .col-sm, .col-md, .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xs-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .col-xs-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }

  .col-xs-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-xs-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }

  .col-xs-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }

  .col-xs-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-xs-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }

  .col-xs-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }

  .col-xs-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-xs-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }

  .col-xs-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }

  .col-xs-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .off-xs-1 {
    margin-left: 8.33333%;
  }

  .off-xs-2 {
    margin-left: 16.66667%;
  }

  .off-xs-3 {
    margin-left: 25%;
  }

  .off-xs-4 {
    margin-left: 33.33333%;
  }

  .off-xs-5 {
    margin-left: 41.66667%;
  }

  .off-xs-6 {
    margin-left: 50%;
  }

  .off-xs-7 {
    margin-left: 58.33333%;
  }

  .off-xs-8 {
    margin-left: 66.66667%;
  }

  .off-xs-9 {
    margin-left: 75%;
  }

  .off-xs-10 {
    margin-left: 83.33333%;
  }

  .off-xs-11 {
    margin-left: 91.66667%;
  }

  @media only screen and (min-width: 768px) {
    .col-sm-1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%;
    }
    .col-sm-2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%;
    }
    .col-sm-5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%;
    }
    .col-sm-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%;
    }
    .col-sm-8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%;
    }
    .col-sm-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%;
    }
    .col-sm-11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%;
    }
    .col-sm-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
    .off-sm-1 {
      margin-left: 8.33333%;
    }
    .off-sm-2 {
      margin-left: 16.66667%;
    }
    .off-sm-3 {
      margin-left: 25%;
    }
    .off-sm-4 {
      margin-left: 33.33333%;
    }
    .off-sm-5 {
      margin-left: 41.66667%;
    }
    .off-sm-6 {
      margin-left: 50%;
    }
    .off-sm-7 {
      margin-left: 58.33333%;
    }
    .off-sm-8 {
      margin-left: 66.66667%;
    }
    .off-sm-9 {
      margin-left: 75%;
    }
    .off-sm-10 {
      margin-left: 83.33333%;
    }
    .off-sm-11 {
      margin-left: 91.66667%;
    }
  }

  @media only screen and (min-width: 960px) {
    .col-md-1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%;
    }
    .col-md-2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%;
    }
    .col-md-5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%;
    }
    .col-md-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%;
    }
    .col-md-8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%;
    }
    .col-md-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%;
    }
    .col-md-11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%;
    }
    .col-md-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
    .off-md-1 {
      margin-left: 8.33333%;
    }
    .off-md-2 {
      margin-left: 16.66667%;
    }
    .off-md-3 {
      margin-left: 25%;
    }
    .off-md-4 {
      margin-left: 33.33333%;
    }
    .off-md-5 {
      margin-left: 41.66667%;
    }
    .off-md-6 {
      margin-left: 50%;
    }
    .off-md-7 {
      margin-left: 58.33333%;
    }
    .off-md-8 {
      margin-left: 66.66667%;
    }
    .off-md-9 {
      margin-left: 75%;
    }
    .off-md-10 {
      margin-left: 83.33333%;
    }
    .off-md-11 {
      margin-left: 91.66667%;
    }
  }

  @media only screen and (min-width: 1200px) {
    .col-lg-1 {
      flex-basis: 8.33333%;
      max-width: 8.33333%;
    }
    .col-lg-2 {
      flex-basis: 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      flex-basis: 33.33333%;
      max-width: 33.33333%;
    }
    .col-lg-5 {
      flex-basis: 41.66667%;
      max-width: 41.66667%;
    }
    .col-lg-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      flex-basis: 58.33333%;
      max-width: 58.33333%;
    }
    .col-lg-8 {
      flex-basis: 66.66667%;
      max-width: 66.66667%;
    }
    .col-lg-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      flex-basis: 83.33333%;
      max-width: 83.33333%;
    }
    .col-lg-11 {
      flex-basis: 91.66667%;
      max-width: 91.66667%;
    }
    .col-lg-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
    .off-lg-1 {
      margin-left: 8.33333%;
    }
    .off-lg-2 {
      margin-left: 16.66667%;
    }
    .off-lg-3 {
      margin-left: 25%;
    }
    .off-lg-4 {
      margin-left: 33.33333%;
    }
    .off-lg-5 {
      margin-left: 41.66667%;
    }
    .off-lg-6 {
      margin-left: 50%;
    }
    .off-lg-7 {
      margin-left: 58.33333%;
    }
    .off-lg-8 {
      margin-left: 66.66667%;
    }
    .off-lg-9 {
      margin-left: 75%;
    }
    .off-lg-10 {
      margin-left: 83.33333%;
    }
    .off-lg-11 {
      margin-left: 91.66667%;
    }
  }
}
