/**
 * This color is the same as we get from ui-kit.
 * The reason it is here though is some strangeness to style
 * going away, but only sometimes :-(
 *
 * See: https://github.com/gramo-org/min-side/issues/1248
 */
.add {
  color: --gramo-sea-green;
}
