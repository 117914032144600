:global {
  .table-sort {
    position: relative;
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 10px;
    &:hover {
      background-color: color(var(--disabled-grey) a(5%));
    }

    &--asc:after,
    &--desc:after,
    &--null::after {
      content: '';
      margin-left: .25rem;
      display: inline-block;
      position: relative;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 55%;
      top: 1px;

    }

    &--desc:after {
      background-image: url(/assets/icons/icon-caret-down.svg);
    }

    &--asc:after {
      transform: rotate(180deg);
      background-image: url(/assets/icons/icon-caret-down.svg);
    }

    &--null::after {
      background: none;
    }
  }
}
