:global {
  .table--tracks {
    & > tbody {
      counter-reset: section;
    }
    margin-left: 10px;
    tr {
      position: relative; /* to enable absolute positioning of the first cell and its children */
      padding-left: 0;
      @media (--desktop) {
        padding-left: 0;
      }
      th {
        border: none;
      }
      td:first-child {
        @media (--desktop) { padding: 0 1rem; }
      }
    }

    & > tbody > tr:not(.table__row--no-count) > td:first-child {

    }
    & > tbody > tr:not(.table__row--no-count) > td:first-child::before {
      counter-increment: section;
      content: counters(section,".", decimal-leading-zero) " ";
      color: var(--white);
      background-color: var(--ocean-blue);
      height: 30px;
      width: 30px;
      text-align: center;
      padding-top: 5px;
      display: inline-block;
      border-radius: var(--input-border-radius);
      margin-left: -5px;
      @media (--desktop) {
        position: relative;
      }
    }

    /* ensure there is enough room to position the 'move' arrows at top and bottom */
    tr:not(.table__row--no-count) {
      min-height: 115px;
      @media (--desktop) {
        min-height: 0;
      }
    }

    /* Row containing Side number */
    tr.table__row--no-count {
      background: none;
      border: 0;
      padding: 2rem 0 .5rem 2rem;
      @media (--desktop) {
        padding: 2rem 0 0 0;
      }

      & > td {
        border: none;
        padding-left: 1rem;
        font-weight: var(--semibold);
        font-size: 1.6rem;
        height: auto;
        @media (--desktop) {
          padding: 1.5rem 0 0 0;
        }

        &:before {
          content: none;
        }
      }
    }
  }

  .table--tracks  {
    /* Up / down */
    td:nth-child(1) {
      order: 1;
    }
    /* Tittel */
    td:nth-child(2) {
      order: 2;
    }
    /* Hovedartist */
    td:nth-child(3) {
      order: 3;
    }
    /* Label */
    td:nth-child(4) {
      order: 5;
    }
    /* ISRC */
    td:nth-child(5) {
      order: 8;
    }
    /* Dato */
    td:nth-child(6) {
      order: 6;
      padding-right: 0;
    }
    /* Varighet */
    td:nth-child(7) {
      order: 3;
      padding-right: 0;
    }
    /* Trash */
    td:nth-child(8) {
      order: 1;
    }
  }

  /* Table cell containing up / down arrows for reordering tracks */
  .table__cell--move {
    position: relative;
    @media (--desktop) {

    }

    & > a {
      position: absolute;
      font-size: 1.6rem;
      @media (--mobile-only) {

      }

      .icon {
        display: block;
      }
    }
  }

  /* Disable first up arrow and last down arrow */

  .table__cell--move a.link--disabled {
    opacity: 0.5;
  }

  /* Table cell trash can button for deleting tracks */
  .table__cell--trash {
    @media(--mobile-only) {
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }
  /* Make release title take up 100% width */
  .table__cell--title {
    flex: 1 0 100%;
  }
  /* Make release title larger and */
  .table__cell--title:not(.table__cell-for-search) {

  }
  /* Don't show data-label attribute or ':' for these cells */
  .table__cell--title,
  .table__cell--move,
  .table__cell--trash {
    &:before {
      content: none;
    }
  }

  /* Edit states */
  .table--tracks {
    .table__cell--move > a,
    .table__cell--trash > a {
      visibility: hidden;
    }
  }

  .table--tracks--edit {
    .table__cell--move > a,
    .table__cell--trash > a {
      visibility: visible;
    }
  }

  .table--tracks {
    .table__cell--move > a {

    }
  }

  .table--tracks--edit {
    .table__cell--move > a {

      &:first-child {
        top: 1.5rem;
        left: -2.5rem;
        @media (--mobile-only) {
          left: -2rem;
          top: 1rem;
        }
      }

      &:last-child {
        left: -1rem;
        top: 1.5rem;
        @media (--mobile-only) {
          left: 3.5rem;
          top: 1rem;
        }
      }
    }
  }

  .table__row--is-editing {
    @media (--mobile-tablet-only) { display: flex; }

    .table__cell--move,
    .table__cell--trash {
      vertical-align: top;
    }

    .table__cell--move::before {
      position: relative;
      @media (--tablet) {
        top: 3rem;
      }
    }

    .table__cell--trash {
      padding-right: 5px;
    }

    .table__cell--move {
      align-items: flex-start;
    }

    .table__cell--trash a {
      @media (--desktop) { top: 1.5rem; }
    }

    .table__cell--trash a, .table__cell--move a {
      display: none;
    }

    .search {
      @media (--mobile-tablet-only) {
        background: var(--off-white);
        padding: 1rem;
        margin-left: -4px;
        width: calc(100% + 2.4rem);
        margin-top: -1rem;
      }
    }

    .form {
      @media (--mobile-tablet-only) {
        width: calc(100% + 1rem);
      }
    }

    .form__input--inline, .btn--inline {
      @media (--mobile-tablet-only) {
        display: block;
        width: 100%;
      }
    }
  }

  .table__cell-for-search {
    width: 100%;
    &::before {
      content: none!important;
    }

    tr {
      margin: 0 -4px;
      padding-left: 0;
      width: calc(100% + 2.4rem);
    }

    .table, .box-list {
      @media (--desktop) {
        width: calc(100% + 4.5rem);
      }
    }
  }
}
