ul.nav-menu {
  list-style-type: none;
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
  li {
    display: block;
    a {
      display: flex;
      gap: 20px;
      flex-direction: row;
      align-items: center;
      padding: 19px;
      text-decoration: none;
      width: 250px;
      @media (--mobile-only) {
        width: 100%;
      }
      svg {
        width: 19px;
        vertical-align: middle;
      }
      span {
        flex: 1;
        width: 230px;
        display: block;
      }
      line-height: 20px;
      &:global(.active):not(:global(.disabled)):not(:global(.ember-transitioning-out)), &:global(.ember-transitioning-in) {
        font-weight: var(--bold);
        line-height: 20px;
      }
    }
  }
  li:hover {
    opacity: 0.8;
  }
  svg path:global(.inactive) {
    visibility: visible;
  }
  svg path:global(.active) {
    visibility: hidden;
  }
  a:global(.active) svg path:global(.active) {
    visibility: visible;
  }
  a:global(.active) svg path:global(.inactive) {
    visibility: hidden;
  }

}
