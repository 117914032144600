.cookie-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  :global(.btn) {
    margin-bottom: 3px;
  }
  p {
    text-align: left;
  }
}
