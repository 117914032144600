ul.colored {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
  li a {
    color: var(--off-white);
    display: block;
    padding: 1rem 0.5rem;
    padding-right: 2rem;
    text-decoration: none;

    &:global(.active):not(:global(.disabled)):not(:global(.ember-transitioning-out)), &:global(.ember-transitioning-in) {
      color: var(--white);
      path {
        fill: var(--white);
      }
    }

    &:hover:not(:global(.disabled)) {
      opacity: 0.7;
    }

    &:global(.disabled) {
      color: var(--disabled-grey);

      &:hover {
        cursor: default;
      }
    }

    span:global(.icon) {
      color: var(--subtle-grey);
      font-size: 2rem;
      position: relative;
      top: 3px;
    }

    svg:global(.icon) {
      height: 15px;
      position: relative;
      top: 2px;
      left: -1px;
      path {
        fill: var(--subtle-grey);
      }
      path:global(.active) {
        fill: var(--white);
      }
    }
  }
}
