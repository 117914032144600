.new-global-search {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    .inner-container{
      width: 100%;
      max-width: 600px;
    }
  }
  
  .dashboard {
    padding-top: 1rem;
    max-width: 1600px;
  }
  
  .dashboard-body {
    flex-wrap: wrap;
    justify-content: left;
  }
  
  .placeholder {
    width: 100%;
    height: 100%;
    background: url("/assets/dashlet.png") no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .message {
      margin: auto;
      background-color: transparent;
    }
  }
  
  .discography-dashlets {
    width: 100%;
    display: flex;
    :global(.dashlet) {
      flex: 1
    }
    @media(--mobile-only) {
      display: block;
    }
  }
  
  .money-dashlets {
    display: flex;
    @media (--mobile-only) {
      display: block;
    }
    align-items: stretch;
    .payment-dashlets {
      flex-basis: 33.33%;
      align-items: stretch;
      display: flex;
      flex-direction: column;
    }
    .graph-dashlet {
      flex-basis: 66.66%;
      display: flex;
      align-items: stretch;
      flex: 1;
    }
  }
  
  .played-dashlet {
    flex: 1;
  }
  