:global {

  /* Icon colors */
  .icon--primary {
    color: var(--ocean-blue);
  }

  .icon--secondary {
    color: var(--subtle-grey);
  }

  .icon--dark::before {
    color: var(--subtle-rey)!important;
  }

  .icon--ui-grey {
    color: var(--subtle-grey);
  }

  .icon--danger {
    color: var(--error);
  }

  .icon--success, .icon--green {
    color: var(--success);
  }

  .icon--blue {
    color: var(--ocean-blue);
  }

  .icon--purple {
    color: var(--magenta);
  }

  .icon--trash:before {
    display: inline-block;
    content: "";
    width: 10px;
    height: 10px;
    background-image: url('/assets/icons/icon-close.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 0.5;
  }


  .icon--close:before {
    display: inline-block;
    content: "";
    width: 10px;
    height: 10px;
    background-image: url('/assets/icons/icon-close.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }

}
