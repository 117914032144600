.browser-check-main {
  background-color: var(--dialog-background-color);
  color: var(--dialog-color);
  padding: 1rem 2rem;
  display: flex;
  border-bottom: solid var(--black) 1px;
  position: absolute;
  z-index: 4000;
  left: 0;
  right: 0;
  top: 0;

  @media(--mobile-only) {
    margin-top: 64px;
  }
}

.more-bg {
  z-index: 9999;
}

.more {
  background-color: var(--base-background-color);
  padding: 2rem;
  overflow-y: auto;
  * {
    text-align: left;
  }
}

.more p, .more ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.actions a {
  padding-left: 2rem;
}

@media (--desktop) {
  .browser-check-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .secondary-message {
    display: inline;
  }
  .more{
    width: 600px;
  }
}
