.helper-text {
  opacity: 0.5;
  font-size: 13px;
  padding: 5px;
}
.loading {
  margin: 0 auto;
  max-width: 200px;
}
.table {
  @media (--mobile-only) {
    padding-left: 10px;
  }
}
.link {
  text-align: center;
  a {
    text-decoration: underline !important;
  }
}
