.controls {
  display: flex;
  justify-content: flex-end;
}
.close {
  cursor: pointer;
}

.file-select {
  margin: 0;
  padding: 0.5rem;
  list-style-type: none;
  border: solid 1px var(--off-white);
  min-width: 400px;
  max-width: 800px;
  max-height: 50vh;
  overflow: auto;
}
.file-select-item {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  &:hover {
    background-color: var(--selection-grey);
  }
  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 400px;
  }
  p {
    color: var(--subtle-grey);
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 400px;
  }
}
