:global {
  .form__label {
    position: relative;
    display: block;
    font-weight: var(--bold);
    padding: 0;
    margin: 0.2rem 0 1.2rem 0;
    text-align: left;
    @media (--tablet) {
      display: inline-block;
      vertical-align: middle;
    }
    
    &--required {

      &:before {
        content: '*';
        color: var(--error);
      }
      
      .required-descr {
        font-weight: var(--normal);
        font-style: italic;
        margin-left: .15rem;
        color: var(--subtle-grey);
      }
    }
    
    &--inline {
      display: inline-block;
      vertical-align: middle;
      span {
        vertical-align: middle;
      }
      input {
        vertical-align: middle;
      }
    }
    
    /* Block labels */
    &--block {
      display: block;
    }
    
    &__help {
      font-size: 1.4rem;
      color: var(--subtle-grey);
      display: block;
    }
  }

  .form__label--info {
    border: 1px solid var(--subtle-grey);
    padding: 2rem;
    p {
      display: inline;
    }
    span {
      font-weight: var(--normal);
      @media (--tablet) {
        margin-left: 2rem;
      }
    }
  }

  .form__label:not(.form__label--inline):not(.form__label--required):not(.form__label--for-button):not(.radio-group .form__label):not(.search__form .form__label):before,
  legend {
    content: '';
    margin-left: .2rem;
  }

  .form__label--inline {
    margin-right: 2rem;
  }

  .form__label--hidden span {
    visibility: hidden;
  }

  .form__label--removed > span {
    visibility: hidden;
    display: block;
    height: 0;
    overflow: hidden;
  }

  .form__label--toggle {
    position: absolute;
    right: -0.5rem;
    top: 3.6rem;
    font-size: 20px;
    &:before {
      content: "\e911";
      font-family: 'minside-icons';
      opacity: 0.5;
    }
  }

  .form__label--toggled::before {
    opacity: 1;
  }

  .form__label--for-button {
    display: block;
    
    span {
      display: inherit;
      height: 0;
      visibility: hidden;
      @media (--tablet) { height: auto; }
    }
    
    .btn {
      display: block;
      margin-top: .6rem; 
    }
  }

  .form__label--in-row {
    align-items: center;
    margin-bottom: 0;
    display: inline-flex;
    vertical-align: middle;
    
    span {
      display: none;
    }
  }

  .form__label--faux {
    margin-bottom: .4rem; /* for elements assuming the visual character of form labels */
  }

  /* For phone number fields */
  .phone-number-field .form__label > span {
    display: block;
    margin-bottom: 3px;
  }

  /* Label select menu help text */
  .label-select-help {
    align-self: flex-end;
    top: -.5rem;
    position: relative;
  }
}
