:app-root {
  main:before {
    background-image: var(--default-background-gradient);
    background-size: var(--gradient-background-size);
  }
}

.controls {
  display: flex;
  align-content: space-between;
  padding-bottom: 3rem;
}

.filters {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

@media (--mobile-only) {
  .controls {
    flex-direction: column;
  }
  .filters {
    flex-direction: column;
  }
}

.actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  gap: 1rem;
}

.active td {
  background-color: rgba(200, 200, 200, 0.2);
}
