.dashlets {
  display: flex;
  :global(.dashlet) {
    flex: 1;
  }
  @media (--mobile-only) {
    display: block;
    width: 100%;
  }
}

.dashlet {
  display: flex;
  flex-direction: column;
  height: 100%;
  .content {
    flex: 1;
  }
}

.rights-dashlet {
  flex-basis: 33.33%;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}
.statements-dashlet {
  flex-basis: 66.66%;
  display: flex;
  align-items: stretch;
  flex: 1;
}

.total {
  font-weight: bold;
  font-size: 35px;
  padding: 3px;
  padding-top: 12px;
}

.helper-text {
  font-size: 13px;
  opacity: 0.5;
  padding-top: 6px;
  margin-bottom: 6px;
}
