:global {

  .form__label.has-error::after {
    color: var(--error);
    content: none;
  }

  .form__label.has-error--simple .form__input {
    border: 1px solid var(--error);
  }

  .form__label.is-valid--simple .form__input {
    border: 1px solid var(--success);
  }

  .form__label.has-error input {
    border: solid var(--error) 1px;
  }
}
