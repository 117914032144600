.distribution-areas {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media (--mobile-only) {
    height: auto;
    flex-direction: column;
  }
}

.area-navigation {
  border-right: solid white 1px;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  header {
    padding-right: 1rem;
    flex: 0;
  }
  @media (--mobile-only) {
    overflow: auto;
    width: 100%;
    border-right: none;
  }
}

.card {
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--container-background-color);
}
.card-title {
  display: flex;
  justify-content: space-between;
}

.area-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  padding-right: 1rem;

  @media (--mobile-only) {
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
  }
}

.area {
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--container-background-color);
  color: var(--white);

  table {
    margin: 0;
  }
  th {
    color: var(--white);
    border: none;
    font-weight: bold;
    text-transform: none;
    font-size: 16px;
  }
  td {
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    font-weight: bold;
  }

  @media (--mobile-only) {
    min-width: 90%;
  }
}
.area-selected {
  background-color: var(--lavender);
  color: var(--black);
  th {
    color: var(--black);
  }
}

.breakdown {
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  .card {
    max-width: 450px;
  }
}
