:global {
  .wizard-steps--minimal {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin-top: 1.5rem;
    vertical-align: middle;

    li {
      margin-right: 1rem;

      & > span {
        display: none;
      }

      &::after, &:last-child::after {
        content: '\2022';
        font-family: 'icomoon';
        font-size: 1.2rem;
        font-size: 3rem;
        line-height: 0;
        color: var(--off-grey);
        margin-left: .3rem;
      }

      &:last-child{
        margin-right: 0;
      }
    }
    li.selected::after {
      color: var(--gramo-sea-green);
    }
  }

  .wizard-steps--overlay {
    @extend .wizard-steps--minimal;
    position: absolute;
    bottom: -50px;
    width: 100%;
    left: 0;

    li {
      &::after, &:last-child::after {
        color: var(--off-grey);
      }

      &.selected::after {
        color: var(--off-white);
      }
    }
  }
}
