:global {
  /* Min Side */
  .site-header {
    position: fixed;

    right: 0px;
    top: 0;
    height: auto;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    background: var(--header-background-color);
    box-shadow: none;
    border: 0;
    padding: 5px;
    padding-left: 3px;
    padding-right: 3px;
    z-index: 3000;

    @media (--mobile-only) {
      left: 0;
    }
    @media (--tablet) {
      top: 15px;
      right: 23px;
    }

    &__branding {
      display: block;
      flex: 1;
      margin: .5rem 0 0 0;
      @media (--tablet) {
        margin-left: 1rem;
      }
      img {
        height: 20px;
        width: 100px;
      }
      a {
        text-decoration: none;
      }
    }

    &__profile-type {
      margin-left: 0.5rem;
      position: relative;
      top: -0.3rem;
      color: var(--off-white);
      width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: text-bottom;
      @media (--tablet) {
        width: auto;
      }
    }
  }
  .site-header-minimal {
    left: 0;
  }
  :global(.impersonating) .site-header {
    top: 55px;
    @media (--mobile-only) {
      top: 40px;
    }
  }
}
