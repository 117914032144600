:root {
  body {
    color: var(--base-font-color) !important;
    font-family: var(--base-font-family) !important;
    letter-spacing: 0;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    margin: 0;

    background-color: var(--base-background-color);
  }

  main {
    transition: background 4.5s;
  }

  input, select {
    font-family: var(--base-font-family);
  }

  /* basic typography */
  h1, h2, h3, h4, h5 {
    font-weight: var(--normal);
    text-align: left;
    padding: 5px;
    padding-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  h1 {
    font-size: var(--h1-font-size);
    line-height: var(--h1-font-size);
    font-family: var(--heading-font-family);
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: var(--thin);
  }

  h2 {
    font-size: var(--h2-font-size);
    line-height: var(--h2-font-size);
    font-family: var(--heading-font-family);
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: var(--thin);
  }

  h3 {
    font-size: var(--h3-font-size);
    line-height: var(--h3-font-size);
    font-family: var(--heading-font-family);
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: var(--medium);
  }

  h4 {
    font-size: var(--h4-font-size);
    font-family: var(--heading-font-family);
    margin: 0;
    font-weight: var(--medium);
  }

  a {
    text-decoration: none;
  }

}
