.disco-header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  header {
    flex: 1;
  }
  @media (--mobile-only) {
    button:global(.btn--download) {
      width: 47px;
      min-width: 47px;
    }
  }
}
.hidden {
  display: none;
}
.tab-form {
  flex: 1;
  line-height: 20px;
  display: flex;
  justify-content: flex-end;
  :global(.form__input) {
    padding: 0.7rem 0.75rem;
  }
  :global(.form__label) {
    margin: 0;
  }
}

:app-root {
  main:before {
    background-image: var(--default-background-gradient);
    background-size: var(--gradient-background-size);
  }
}
