.impersonate-banner {
    color: var(--base-font-color);
    font-style: normal;
    margin: 0;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
    font-weight: var(--medium);
    background: var(--ocean-blue);
    display: flex;
    align-items: center;
    flex: 0 0 40px;
    justify-content: center;
    :global(.icon) {
      font-size: 1.6rem;
      margin: 0 0 0 0;
      height: 12px;

      path {
        fill: var(--white);
      }
    }

    .impersonate-close {
      cursor: pointer;
      position: absolute;
      right: 0;
      padding: 0.5rem;
    }
    @media (--mobile-only) {
      .impersonate-text {
        display: none;
      }
    }
  }
