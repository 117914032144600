.discography-source-container {
  padding: 1.7rem 0 0 0;

  .discography-source-title {
    color: #333333;
    font-family: var(--base-font-family);
    font-size: var(--minside-font-size);
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2rem;
    margin-bottom: 1rem;
    margin-left: 2rem;

    svg,
    img {
      height: 100%;
      vertical-align: middle;
      margin-right: 14px; /* FIXME */
    }

    span {
      border-bottom: 2px solid;

      &.my-discography {
        border-bottom-color: var(--gramo-purple);
      }

      &.gramo-discography {
        border-bottom-color: var(--gramo-dark-sea-green);
      }
    }
  }
}

.result-category {
  padding: 0.75rem 2rem 0.75rem 52px; /* FIXME */
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  text-align: left;

  a {
    visibility: hidden;
  }
  &:hover a {
    visibility: visible;
  }

  .result-category-title {
    width: 100%;
    flex-direction: column;
    font-family: var(--base-font-family);
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.9rem;
    svg,
    strong,
    span {
      vertical-align: middle;
      display: inline-block;
    }
    .result-category-title-search-term {
      margin-left: 0.4rem;
    }

    @media (--tablet) {
      width: 70%;
    }
  }
  .result-category-result-container {
    width: 100%;
    padding: 0 0.75rem;
    flex: 1 1 0;
    flex-direction: column;

    @media (--tablet) {
      width: 30%;
    }
    @media (--mobile-only) {
      display: none;
    }
  }

  &:hover {
    background-color: var(--off-white);
    cursor: pointer;
  }
}
