:global {
  .list {
    list-style: none;
    padding: 0 1rem;
    border: 0;

    &__header {
      background: var(--off-white);
      margin: -1rem -1rem 1rem -1rem;
      padding: 1rem 1rem 1rem 0;
      position: static;
      @media (--desktop) {
        margin: -2rem -2rem 1rem -2rem;
        padding: 1rem;
      }
    }

    &__header > * {
      display: block;
      @media (--tablet) {
        align-self: center;
      }
    }

    &__header {
      h3 {
        font-size: 2.4rem;
      }
    }

    &__header--action {
      text-align: right;

      & > .btn {
        display: inline-block;
      }
    }

    &__item {
      padding: 1rem 0;
      border-bottom: 1px solid var(--silver);

      a {
        text-decoration: none;
        font-weight: var(--semibold);
      }
    }
  }

  .box-list {
    background: none;
    padding: 0;
    counter-reset: item;

    & > li {
      padding: 1rem;
      position: relative;
      margin-bottom: 0.5rem;
      @media (--desktop) {
        padding-left: 3rem;
        margin-left: 0.5rem;
      }
    }
  }

  .box-list--numbered {
    counter-reset: item;
  }

  .box-list--numbered > li {
    @media (--desktop) {
      &:before {
        counter-increment: item;
        content: counters(item, '.') ' ';
        position: absolute;
        left: -5px;
        top: 25px;
        list-style-type: decimal-leading-zero;
        height: 30px;
        width: 30px;
        border-radius: var(--input-border-radius);
        background-color: var(--disabled-grey);
        color: var(--white);
        text-align: center;
        display: inline-block;
        padding-top: 5px;
      }
      &.disabled:before {
        background-color: var(--ocean-blue);
      }
    }
  }
}
