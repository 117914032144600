.codes {
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
}
.helper-text {
  opacity: 0.5;
  font-size: 13px;
  padding: 5px;
}
