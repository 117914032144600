.performer-list {
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  z-index: 1;
  min-width: 300px;
  ul {
    margin: 0;
  }
}

.performer-column {
  position: relative;
}
.performer-column:hover .performer-list {
  display: block;
}
@media (--mobile-only) {
  .performer-column {
    display: none;
  }
}

.performer-count {
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.expandable {
  display: none;
}

.expanded {
  display: table-row
}

.divider {
  border-right : solid var(--off-white) 10px;
}

.divider + td, .divider + th {
  padding-left: 1.1rem!important;
}

.catalog-number {
  text-overflow: ellipsis;
  max-width: 20rem;
  overflow: hidden;
  white-space: nowrap;
}

.active {
  background-color: var(--forest-green);
}
