:global {
  .card {

    flex: none;
    display: block;
    box-shadow: none;
    box-shadow: 0 4px 0 0 var(--gramo-turkis);
    text-align: center;
    padding: 1rem 0;
    text-decoration: none;
    @media (--tablet) { padding-top: 2rem; }

    &--with-border {
      border: 1px solid var(--silver);
      margin: 1rem 0 2rem 0;
      box-shadow: none;
      padding: 1rem;

      &:last-child {
        margin-bottom: 3rem;
      }
    }

    &--disabled {
      background: var(--off-white);
      pointer-events: none;

      h2, .icon {
        color: var(--off-grey);
      }
    }

    .icon {
      font-size: 3.2rem;
      @media (--tablet) { font-size: 4rem; }
    }

    em {
      font-weight: var(--semibold);
      display: block;
      font-style: normal;
    }
  }

  a.card.active, a.card:hover {
    background: color(var(--gramo-sea-green) tint(70%));
    border-bottom-color: var(--gramo-sea-green);
    color: var(--gramo-sea-green);
    box-shadow: 0 4px 0 0 var(--gramo-sea-green);

    .icon, .card__header {
      color: inherit;
    }
  }
}
