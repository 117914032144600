.playbacks-list {
  background-color: white;
  min-height: 85vh;
  border-radius: 5px;
  padding: 2rem;

  :global(.table-sort--desc):after {
    background-image: url(/assets/icons/icon-caret-down-dark.svg);
  }
  :global(.table-sort--asc):after {
    background-image: url(/assets/icons/icon-caret-down-dark.svg);
    transform: rotate(180deg);
  }
}

.filter {
  display: inline-block;
  background-color: var(--lavender);
  padding: 3px;
  padding-left: 8px;
  padding-right: 3px;
  span.value {
    font-weight: bold;
  }
  span.close {
    font-size: 8px;
    width: 20px;
    display: inline-block;
    text-align: center;
    margin-left: 3px;
    cursor: pointer;
  }
}

.playbacks-filters {
  margin-bottom: 40px;

  .filter-select {
    padding: 15px;
  }

  .filter-select--open {
    box-shadow: 0px 5px 24px var(--selection-grey);
    z-index: 2;
    background: var(--white);
  }

  .filter-header {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--selection-grey);
    cursor: pointer;

    path {
      fill: var(--text-black);
    }
  }

  .filter-content {
    margin-top: 16px;

    label {
      margin-bottom: 5px;
    }
  }
}

.search-form-input {
  width: 400px!important;
  padding: 1.2rem 0.75rem;
  margin-bottom: 0;
  border: 1px solid #dedede;
  background-color: #ffffff;
  font-weight: 400;
  margin-right: 3px;
}

select.search-form-input {
  padding: 1.2rem;
}

.playbacks-controls {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
  & > div {
    flex: 1;
  }
  input {
    border-radius: 22.5px!important;
  }
}

@media (--mobile-only) {
  .playbacks-controls {
    flex-direction: column;
  }
  .search-form-input {
    width: 100%!important;
  }
}

.claims-list-link {
  display: flex;
  justify-content: left;
  padding-bottom: 2rem;
}

.divider {
  border-right : solid var(--off-white) 10px;
}

.divider + td, .divider + th {
  padding-left: 1rem!important;
}

.playlist-table {
  position: relative;
}

.playlist-table th {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.playlist-headers th {
  position: sticky;
  top: -1.6rem;
  background-color: var(--white);
  z-index: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  :global(.icon--icon-notice) {
    height: 14px;
    path {
      fill: var(--subtle-grey);
    }
  }
}
