.profile-avatar {
  width: 43px;
  height: 43px;
  border: solid var(--ocean-blue) 2px;
  border-radius: 50%;
  text-align: center;
  padding-top: 9px;
  display: none;
  @media (--mobile-only) {
    display: inline-block;
    background-color: var(--ocean-blue);
    font-weight: 600;
    svg {
      margin-top: 2px;
      path {
        fill: var(--white);
      }
    }
  }
}