.recording {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.recording-info {
  flex: 1;
}

.recording-remove {
  display: flex;
  align-items: center;
  justify-content: center;
}
