:global {
  .pagination {
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    &--boxed {
      margin-bottom: 1.2rem;
    }
    display: flex;
  }

  .pagination > .pagination__item {
    text-align: center;
    display: inline-block;
    border: none;
    color: var(--white);
    margin: 0;
    margin-left: 1px;
    &--current a {
      background: var(--ocean-blue);
    }

    a {
      text-decoration: none;
      padding: 1.2rem 0.6rem;
      min-width: 3.2rem;
      display: inline-block;
      background: var(--container-background-color);

      &:hover {
        opacity: 0.7;
        color: #fff;
      }
    }

    &--previous,
    &--next {
      display: inline-block;

    }

    &--current {
      a {
        color: var(--white);
        background: var(--ocean-blue);
        &:hover {

        }
      }
    }

    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .pagination__empty {
    display: none;
  }

  .icon--pagination-previous,
  .icon--pagination-next {
    position: relative;
    top: 1px;
  }

  .pagination__item--previous a {
    border-radius: var(--compact-border-radius) 0 0 var(--compact-border-radius);
  }
  .pagination__item--next a {
    border-radius: 0 var(--compact-border-radius) var(--compact-border-radius) 0;
  }

  .small-previous {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    min-width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .small-next {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    min-width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
