:global {
  .table__row--selected {
    background: color(var(--rovary-dark-grey) shade(7%));
  }

  .row__delete {
    .icon {
      font-size: 2rem;
      line-height: 20px;
      position: absolute;
      right: -10px;
      bottom: 27px;
      opacity: 0.3;
    }

    &--half {
      @media (--tablet) {
        right: calc(50% - 1.5rem);
      }
    }

    &--for-select {
      @media (--tablet) {
        left: calc(280px + 1.5rem);
      }
    }
  }

  /* Data types for rows */

  tr[data-src] {
    position: relative;
    @media (--desktop) {
      position: static;
    }
  }

  tr[data-src] > td:first-child {
    @media (--desktop) {
      position: relative;
    }

    &::before {
      vertical-align: middle;
      position: relative;
      top: -1px;
      left: 0rem;
      margin-left: -0.5rem;
      font-family: 'minside-icons';
    }

    &::after {
      content: '';
      width: 5px;
      position: absolute;
      min-height: 4.8rem; /* force height for ie >=10 */
      top: 0;
      bottom: 0;
      left: 0;
    }
  }

  tr[data-src='local'] {
    background: color(var(--gramo-blue) tint(93%)) !important;
    & > td:first-child::before {
      content: '\e903';
      color: var(--gramo-blue);
    }
    & > td:first-child::after {
      background-color: var(--gramo-blue);
    }
  }

  tr[data-src='global'] {
    background: color(var(--gramo-green) tint(90%)) !important;
    & > td:first-child::before {
      content: '\e920';
      color: var(--gramo-green);
    }
    & > td:first-child::after {
      background-color: var(--gramo-green);
    }
  }
}
