:global {
  .btn--download {
    background-image: url("/assets/icons/icon-download.svg");
    background-position: right 1.3rem center;
    background-repeat: no-repeat;
  }

  .download-title {
    display: none;
  }

  @media (--tablet) {
    .btn--download {
      padding-right: 4rem;
    }
    .download-icon {
      margin-top: 0rem;
      padding: 0;
    }
    .download-title {
      display: inline;
    }
  }
  @media(--mobile-only) {
    .btn--download {
      min-width: 47px;
      width: 47px;
    }
  }
}
