:global {
  .file-upload {
    text-align: inherit;
    margin-top: 0;
    display: inline-block;
    
    &--in-form {
      margin-bottom: 1.2rem;
    }
    
    &__inner.is-visible {
      position: fixed;
      top: 5.1rem;
      left: 0;
      right: 0;
      min-width: 0;
      @media (--tablet) {
        right: 5rem;
        position: absolute;
        top: calc(100% - 5rem);
        left: auto;
        min-width: 450px;
      }
      
      &:before {
        right: -1rem;
        text-shadow: 2px 0 2px rgba(0,0,0,0.3);
      }
    }
    
    &--required {
      &:before {
        content: '*';
        color: var(--gramo-red);
      }
    }
  }

  .file-upload__header {
    h2 {
      margin-bottom: 0;
    }
    .icon {
      font-size: 1.8rem;
    }
  }

  .file-upload__header--action {
    .icon {
      font-size: 1.6rem;
      position: relative;
      top: 2px;
      margin-right: .25rem;
    }
  }

  .file-upload__list {
    .icon {
      font-size: 1.8rem;
    }
  }
}
