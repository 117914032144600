:global {
  @keyframes :global(contentPlaceholder) {
    0%{
        background-position: -40rem 0
    }
    100%{
        background-position: 40rem 0
    }
  }

  .content-placeholder {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: contentPlaceholder;
    animation-timing-function: linear;
    border-radius: var(--input-border-radius);
    background: linear-gradient(90deg, var(--container-dark-grey) 5.97%, var(--subtle-grey) 89.6%);
    background-size: 100rem 10rem;
    height: 22px;
    position: relative;
    margin: 5px;
  }
}
