.circle-button {
  width: 43px;
  height: 43px;
  border: solid #A41681 2px;
  border-radius: 50%;
  text-align: center;
  padding-top: 9px;
  display: inline-block;
  color: white;
  background-color: #A41681;
  font-weight: 600;
  svg {
    margin-top: 2px;
    path, line {
      stroke: white;
    }
  }
  @media(--tablet) {
    :global(.icon--icon-close) {
      visibility: hidden;
    }
  }
  :global(.icon--icon-close) path {
    fill: white;
    stroke: none;
  }
}
