:global {
  .radio-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: .6rem;
    
    label, .check, .check:before {
      width: 4.5rem;
      height: 4rem;
      font-size: 1.8rem;
    }

    
    .form__label {
      z-index: 0;
      margin: 0.5rem 1rem 0 0;
      
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      transition: all 0.25s linear;
      text-align: center;
      padding-top: .6rem;
      
      &.has-error {
        border: 1px solid var(--error);
      }

      &.has-error:after {
        content: none;
      }
    }
    
    input[disabled] ~ span {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
