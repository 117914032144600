
.empty {
  display: flex;
  padding: 10px;
}
.most-played {
  @media(--mobile-only) {
    width: 100% !important;
    tr {
      overflow: hidden;
    }
    tr td {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
}
