.playbacks {
  width: 99%;
  padding: 2rem;
  margin-left: 6rem;
  height: 90%;

  header {
    color: white;
    display: flex;
    justify-content: space-between;
    h2 {
      text-align: left!important;
      margin: 0!important;
      padding: 0!important;
    }
  }
}

.playbacks-list {
  background-color: white;
  border-radius: 5px;
  padding: 2rem;
}

.search-form-input {
  width: 400px!important;
}

.playbacks-controls {
  display: flex;
  align-items: flex-start;
}

@media (--mobile-only) {
  .playbacks {
    margin-left: 0;
  }
}

:app-root {
  :global(.simple) main {
    background-color: inherit;
  }
  :global(.simple) main:before {
    background-image: var(--default-background-gradient) !important ;
    background-size: var(--gradient-background-size) !important;
  }
}
