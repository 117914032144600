.profile-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-top: 10px;
  button {
    flex-basis: 100%;
    margin-top: 8px;
    vertical-align: middle;
    svg {
      margin-left: 5px;
      vertical-align: middle;
      path:global(.active) {
        fill: var(--white);
      }
      path:global(.stroke.active) {
        stroke: var(--white);
      }
      path:global(.inactive) {
        display: none;
      }
    }
  }
}
