:global {
  .table__cell--action {
    flex: 1;
    align-self: flex-end;
    margin-top: -3rem;
    position: relative;
    text-align: right;
    @media (--desktop) {
      margin-top: 0;
      top: .25rem;
      position: static;
    }
    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .table__cell--file-upload {
    order: 2;
  }

  /* Prevent text-wrap in table cells showing date */
  [data-label="Utgitt"] {
    white-space: nowrap;
  }
}
