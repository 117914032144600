
/* global things should be moved to the UI-kit eventually */
:global(header.new-menu) {
  height: 54px;
  background-color: var(--header-background-color);
  @media(--tablet) {
    height: 54px;
    border-radius: var(--input-border-radius);
  }
}

.branding {
  @media (--tablet) {
    display: none !important;
  }
}

.profile-nub {
  color: white;
  border-radius: 0 22.5px 22.5px 0;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 5px;
  transition: background-color 0.2s;
}
.profile-nub:hover {
  background-color: color(var(--disabled-grey) a(30%));
  transition: background-color 0.2s;
  cursor: pointer;
  @media (--mobile-only) {
    background: none;
  }
}

.profile-nub {
  display: flex;
  height: 50px;
  padding: 1rem;
}

.profile-avatar {
  width: 50px;
  height: 50px;
  border: solid #0095DA 2px;
  border-radius: 50%;
  text-align: center;
  padding-top: 9px;
  display: inline-block;
  @media (--mobile-only) {
    background-color: #0095DA;
    font-weight: 600;
  }
}

.profile-name {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 20px;
  text-align: left;
  gap: 0.2rem;
  @media (--mobile-only) {
    display: none;
  }
}

.profile-role-name {
  font-weight: 600;
  font-size: 20px;
}

.profile-menu-status {
  width: 14px;
  transform: rotate(0);
  @media (--mobile-only) {
    display: none;
  }
}
.profile-menu-status-open {
  transform: rotate(180deg);
}

header:global(.new-menu) {
  :global(.site-header__branding) {
    flex: 0;
    @media (--mobile-only) {
      flex: 1;
    }
  }
  :global(.search) {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .mobile-search {
    @media(--tablet) {
      display: block;
      border-right: solid var(--subtle-grey) 1px;
      padding-right: 5px;
      :global(.circle-button) {
        border-radius: 22.5px 0 0 22.5px;
        background-color: transparent;
        border-color: transparent;
        &:hover {
          background-color: color(var(--disabled-grey) a(30%));
          cursor: pointer;
        }
      }
    }
  }
  @media (--mobile-only) {
    .search {
      display: none;
    }
    .mobile-search {
      display: block;
    }
  }
}


