:global {
  .form__input {
    padding: 1.2rem 0.75rem;
    display: block;
    width: 100%;

    margin-bottom: 0;
    border: 1px solid var(--disabled-grey);
    background-color: var(--white);
    font-weight: var(--normal);
    margin-right: 3px;

    &[type="text"], &[type="password"], &[type="email"], &[type="url"], &[type="tel"] {
      -webkit-appearance: none;
      border-radius:0;
    }
    /*** Remove 'x' from inputs on IE ***/
    &::-ms-clear {  display: none; width : 0; height: 0; }
  }

  /* Checkboxes/radio buttons */
  input[type="radio"], input[type="checkbox"],
  .form__input--checkbox,
  .form__input--radio {
    display: inline-block;
    width: auto;
    margin-right: 0.2rem;
    height: 20px;
    width: 20px;
  }

  /* Date inputs */
  .form__input--date,
  .form__input[type="date"] {
    padding: 1rem .75rem;
    min-height: 45px;
    background: #fff url(img/calendar.svg) center right 1rem no-repeat;
    max-width: 400px;
    min-width: 115px;

    @media (--tablet) {
      min-width: 0;
      max-width: 400px;
    }

    &::-webkit-calendar-picker-indicator,
    &::-webkit-inner-spin-button {
      opacity: 0!important;
      -webkit-appearance: none!important;
    }
  }

  /* Hack to vertically center text in input on ie/edge */
  _:-ms-lang(x), .form__input[type="date"] {
    line-height: 0;
  }
  /* Hack to fix heights of date inputs in IE10/11 */
  _:-ms-input-placeholder, :root .form__input[type="date"] {
    /*box-sizing: content-box;*/
    padding: 1.2rem 0 1.2rem .5rem;
    width: 95%;
  }

  input::-webkit-input-placeholder, input:-ms-input-placeholder, input::placeholder {
    font-weight: var(--thin);
  }

  input[type="number"],
  input[type="time"] {
    height: auto;
    max-width: 150px;
  }

  .form__input[disabled] {
    border-color: var(--disabled-grey);
    background-color: var(--disabled-grey);
    color: var(--subtle-grey);
    cursor: not-allowed;
  }

  .form__input--discreet[disabled] {
    border: 0;
    background: none;
    color: inherit;
    text-transform: uppercase;
    font-weight: var(--bold);
  }

  input[type="checkbox"].form__input--toggle-visibility {
    visibility: hidden;
  }

  .form__input--stretch {
    max-width: none!important;
    width: 100%!important;
  }

  .form__input--textarea {
    margin-bottom: 1rem;
  }

  .form__input__errors, .form__input__valid {
    position: relative;
    top: -1rem;
    font-size: 1.5rem;
    font-weight: var(--medium);
    p {
      color: var(--error);
      text-align: left;
    }
  }

  .form__input__valid {
    p {
      color: var(--success);
    }
  }

  .form__input--small {
    padding: .2rem .5rem;
    margin: 0;
  }

  .form__input--inline {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form__input--300 {
    @media (--tablet) { width: 300px; }
  }

  .form__input--170 {
    @media (--tablet) { width: 170px; }
  }

  .form__input--80 {
    @media (--tablet) { width: 80px; }
  }

  .form__input--60 {
    @media (--tablet) { width: 60px; }
  }

  .form__input--30 {
    width: 30px; padding-right: 0;
  }

  /* Textarea */
  .form__input--textarea {
    resize: vertical;
    background: var(--white);
    min-height: 100px;
    max-width: 400px;
    margin-bottom: 1.2rem;
  }

  /* overrides chrome autofill yellow background */
  input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px var(--white) inset;
  }

  /* Flatpickr styles */
  .flatpickr-wrapper {
    display: block;
  }

  .flatpickr-day {
    border-radius: 2px;
  }

  .flatpickr-months .flatpickr-month svg, .flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
    height: 32px;
  }

  .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: var(--white);
    border-color: var(--white);
  }

  .form__input__icons {
    visibility: hidden;
    position: absolute;
    bottom: 13px;
    right: 10px;
    @media (--desktop) { visibility: visible; }
  }

  .input--search {
    border-radius: var(--input-border-radius) !important;
    padding-left: 5px;
    padding-right: 5px;
    background-position: left center;
    background-image: url('/assets/icons/icon-search.svg');
    background-repeat: no-repeat;
  }
  .input--control {
    border-radius: var(--input-border-radius);
  }
}
